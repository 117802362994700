import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik, FormikHelpers } from 'formik';
// material
import { DateTimePicker, LoadingButton, DatePicker } from '@mui/lab';
import { Alert, Box, Card, Grid, Stack, TextField } from '@mui/material';

// ----------------------------------------------------------------------

const NewOrderSchema = Yup.object().shape({
  location: Yup.string(),
  email: Yup.string().email(),
  password: Yup.string()
});

type ProviderEditFormProps = {
  isEdit: boolean;
  onSubmit: (formValues: any, formickHelpers: FormikHelpers<any>) => void;
  provider?: any;
  isLoading: boolean;
};

const initalDate = { dueDate: new Date() as unknown as string };

export default function ProviderEditForm({
  isEdit,
  onSubmit,
  provider,
  isLoading
}: ProviderEditFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...provider,
      ...(!isEdit && initalDate)
    },
    validationSchema: NewOrderSchema,
    onSubmit
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
    setTouched,
    isValid
  } = formik;

  return (
    <FormikProvider value={formik}>
      {Object.keys(errors).length > 0 && (
        <Alert sx={{ marginBottom: 2 }} severity="error">
          Form is Incomplete
        </Alert>
      )}
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="State"
                    {...getFieldProps('state')}
                    error={Boolean(touched.state && errors.state)}
                    helperText={touched.state && errors.state}
                  />
                  <TextField
                    fullWidth
                    label="City"
                    {...getFieldProps('city')}
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                  />
                  <TextField
                    fullWidth
                    label="Password"
                    {...getFieldProps('password')}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Email"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Stack>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={!touched || !isValid}
                    loading={isSubmitting || isLoading}
                  >
                    {!isEdit ? 'Create User' : 'Save Changes'}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

// material
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import useSettings from 'hooks/useSettings';

// ----------------------------------------------------------------------

interface LogoProps extends BoxProps {
  small: boolean;
}

export default function Logo({ sx, small }: LogoProps) {
  const theme = useTheme();
  const settings = useSettings();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: small ? 65 : 200, height: small ? 50 : 95, ...sx }}>
      <img
        style={{ width: '100%' }}
        src={`/static/brand/${
          small
            ? `HELP_Logo_Icon${settings.themeMode === 'dark' ? 'WH' : 'DK'}.png`
            : `HELP_Logo${settings.themeMode === 'dark' ? 'WH' : 'DK'}.png`
        }`}
      />
    </Box>
  );
}

// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  resetPassword: path(ROOTS_AUTH, '/forgot-password')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    account: path(ROOTS_DASHBOARD, '/account'),
    login: path(ROOTS_DASHBOARD, '/auth/login')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    patients: path(ROOTS_DASHBOARD, '/patients'),
    patientResults: path(ROOTS_DASHBOARD, '/results'),
    patientLessons: path(ROOTS_DASHBOARD, '/lessons'),
    patientLesson: path(ROOTS_DASHBOARD, `/lesson`),
    patientEdit: (id: number) => path(ROOTS_DASHBOARD, `/patients/edit/${id}`),
    myProviders: path(ROOTS_DASHBOARD, '/my-providers'),
    myProvidersEdit: (id: number) => path(ROOTS_DASHBOARD, `/providers/edit/${id}`),
    reports: path(ROOTS_DASHBOARD, `/reports`),
    familyRecording: path(ROOTS_DASHBOARD, `/family-recording`),
    lessonAccess: path(ROOTS_DASHBOARD, `/lesson-access`)
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

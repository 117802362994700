import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import Page from 'components/Page';
import { useGetUserQuery } from 'generated/graphql';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import HHIE from './quizzes/HHIE';
import CCP from './quizzes/CCP';
import COSI from './quizzes/COSI';
import { CircularProgress } from '@mui/material';

const steps = ['HHIE', 'COSI', 'CCP'];

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

function PatientOnboarding() {
  const { data, loading } = useGetUserQuery({ notifyOnNetworkStatusChange: true });
  const [activeStep, setActiveStep] = useState<0 | 1 | 2 | 3 | undefined>(undefined);
  const hhieScore = data?.getUser?.hhieScore;
  const cosiScore = data?.getUser?.cosiScore;
  const ccpScore = data?.getUser?.ccpScore;

  const displayCorrectScore = (step: number): number | undefined | null => {
    switch (step) {
      case 0:
        return hhieScore;
      case 1:
        return 0;
      case 2:
        return ccpScore;
    }
  };

  useEffect(() => {
    if (!hhieScore) {
      return setActiveStep(0);
    }
    if (!cosiScore) {
      return setActiveStep(1);
    }
    if (!ccpScore) {
      return setActiveStep(2);
    }
    return setActiveStep(3);
  }, [hhieScore, cosiScore, ccpScore]);

  return (
    <RootStyle title="Patient | Onboarding">
      <Box
        sx={{
          width: 500,
          margin: 'auto',
          paddingTop: 4,
          paddingLeft: 1,
          paddingRight: 1
        }}
      >
        <h2 style={{ textAlign: 'center' }}>Welcome {data?.getUser?.name ?? 'User'}!</h2>
        <p style={{ textAlign: 'center', marginBottom: 16 }}>
          You must complete the onboarding quizzes
        </p>
        <Box sx={{ width: '100%', marginBottom: 2 }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{`${label} (${displayCorrectScore(index) ?? 0})`}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {loading ? (
          <Box sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
            <h2>Waiting for User Data...</h2>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {activeStep === 0 && <HHIE />}
            {activeStep === 1 && <COSI />}
            {activeStep === 2 && <CCP />}
          </>
        )}
      </Box>
    </RootStyle>
  );
}

export default PatientOnboarding;

import { gql } from '@apollo/client';
import { userFragment } from './user.fragments';

export const GET_USER = gql`
  ${userFragment}
  query GetUser {
    getUser {
      ...userFragment
    }
  }
`;

export const GET_PROVIDERS = gql`
  ${userFragment}
  query GetProviders {
    getProviders {
      ...userFragment
    }
  }
`;

import { filter, flatten } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress
} from '@mui/material';
// routes
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import QueryString from 'qs';
import { useSnackbar } from 'notistack';
import BasicModal from './BasicModal';
import BasicDropzone from './DropzoneBasic';
import { Box } from '@mui/system';
import LoadingScreen from 'components/LoadingScreen';
import {
  ProviderHeader,
  ProviderMoreMenu,
  ProviderToolBar
} from 'components/_dashboard/user/providers';
import { PATH_DASHBOARD } from 'routes/paths';
import { useQuery } from '@apollo/client';
import { GET_PROVIDERS } from 'graphql/user/user.queries';
import { GetProvidersQuery, useGetProvidersQuery } from 'generated/graphql';

// ----------------------------------------------------------------------
//Table Headings First name, Last name, email, unique ID, Balance owed, balance paid, status (Paid, Unpaid), due date
const TABLE_HEAD = [
  { id: 'city', label: 'City', alignRight: false },
  { id: 'state', label: 'State', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'userLogin', label: 'Last Login', alignRight: false },
  { id: 'hello', label: ' ' }
];

// ----------------------------------------------------------------------

type Anonymous = Record<string | number, string>;

function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(
  array: GetProvidersQuery['getProviders'] | null,
  comparator: (a: any, b: any) => number,
  query: string
): GetProvidersQuery['getProviders'] | null {
  if (!array) return [];
  const stabilizedThis = array?.map((el: any, index: number) => [el, index] as const);
  stabilizedThis?.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // if (query) {
  //   return filter(array, (_order) => {
  //     return (
  //       _order?.firstName?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
  //       _order?.lastName?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
  //       _order?.email?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
  //       String(_order?.balanceOwed)?.toLowerCase().indexOf(query.toLowerCase()) !== -1
  //     );
  //   });
  // }
  return stabilizedThis?.map((el: any) => el[0]) ?? [];
}

export default function ProviderList({ onEdit }: { onEdit: (id: number, row: any) => void }) {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  // const {
  //   deleteExistingOrder,
  //   getOrders,
  //   createNewOrder,
  //   isLoading: isOrdersLoading,
  //   orders
  // } = useOrders();
  const { data: providers, loading: providersLoading } = useGetProvidersQuery();
  const [page, setPage] = useState<number>(0);
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { order, orderBy } =
    (location?.state as { [key: string]: string | null | undefined }) ?? {};
  const orderDirectionDefault = order ? (order === 'asc' ? 'asc' : 'desc') : 'asc';
  const [orderDirection, setOrder] = useState<'asc' | 'desc'>(orderDirectionDefault);
  const [selected, setSelected] = useState<number[]>([]);
  const [orderByKey, setOrderBy] = useState(orderBy ?? 'id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalOpen, setModalOpen] = useState(false);
  const filteredProviders = applySortFilter(
    providers?.getProviders,
    getComparator(orderDirection, orderByKey),
    filterName
  );

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (providers?.getProviders?.length ?? 0)) : 0;

  const handleRequestSort = (property: string) => {
    const isAsc = orderByKey === property && orderDirection === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    setSelected((prevSelected) =>
      prevSelected.length === 0
        ? providers?.getProviders?.map((provider) => parseInt(provider.id)) ?? prevSelected
        : prevSelected
    );
  };

  const handleClick = (orderId: number | undefined) => {
    if (!orderId) return;

    const selectedIndex = selected.indexOf(orderId);
    let newSelected: number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, orderId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  if (providersLoading) {
    return <LoadingScreen />;
  }

  return (
    <Card>
      <ProviderToolBar
        label="Provider List"
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
        onDeleteOrders={() => {}}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ProviderHeader
              order={orderDirection}
              orderBy={orderByKey}
              headLabel={TABLE_HEAD}
              rowCount={providers?.getProviders?.length ?? 0}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredProviders?.map((row) => {
                const { id, email, lastLogin, city, state } = row;
                const isItemSelected = selected.indexOf(parseInt(id)) !== -1;

                return (
                  <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox checked={isItemSelected} onClick={() => handleClick(id)} />
                    </TableCell> */}
                    <TableCell align="left">{city}</TableCell>
                    <TableCell align="left">{state}</TableCell>
                    <TableCell align="left">{email}</TableCell>
                    <TableCell align="left">{lastLogin ?? 'Never'}</TableCell>
                    <TableCell align="right">
                      <ProviderMoreMenu
                        onEdit={() => onEdit(parseInt(id), row)}
                        onDelete={() => {}}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {/* {isOrderNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
          </Table>
        </TableContainer>
      </Scrollbar>

      {/* <TablePagination
            lang="US"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={providers?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
    </Card>
  );
}

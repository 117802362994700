import LoadingScreen from 'components/LoadingScreen';
import { useGetUserQuery } from 'generated/graphql';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

// routes
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { data, loading } = useGetUserQuery();

  if (loading) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}

import { useCallback, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { CHANGE_PASSWORD, INIT_FORGOT_PASSWORD, USER_LOGIN } from 'graphql/user/user.mutations';
import { GET_USER } from 'graphql/user/user.queries';
import { GetUserDocument } from 'generated/graphql';

function useUser() {
  // const isLoading = useSelector((state: RootState) => state.merchant.isLoading);
  // const account = useSelector((state: RootState) => state.merchant.account);
  // const login = useCallback(({ email, password }) => dispatch(loginUser({ email, password })), []);
  // const logout = useCallback(({ email, password }) => dispatch(loginUser({ email, password })), []);
  // const reduxGetMerchant = useCallback(() => dispatch(getMerchant()), []);
  // const updateSettings = useCallback(
  //   ({ email, businessName, address, checkoutUrl }) =>
  //     dispatch(updateMerchant({ email, businessName, address, checkoutUrl })),
  //   []
  // );
  const [_loginUser, { data, loading: userLoginLoading }] = useMutation(USER_LOGIN, {
    refetchQueries: [GetUserDocument]
  });
  const [getUser, { data: user, loading: userGetLoading }] = useLazyQuery(GET_USER);
  const [_changePassword, { data: changePasswordResponse, loading: changePasswordLoading }] =
    useMutation(CHANGE_PASSWORD);
  const [_initPasswordChange, { data: initPasswordData, loading: initPasswordLoading }] =
    useMutation(INIT_FORGOT_PASSWORD);

  const loginUser = useCallback(({ email, password }) => {
    return _loginUser({ variables: { email, password } });
  }, []);

  const changePassword = useCallback(({ password, token }) => {
    return _changePassword({ variables: { password, token } });
  }, []);

  const initPasswordChange = useCallback(({ email }) => {
    return _initPasswordChange({ variables: { email } });
  }, []);

  return {
    merchant: user || data,
    account: user || data,
    // isLoading,
    isLoading: userGetLoading || userLoginLoading,
    // account, //anything that is using "isAuthenticated" you will use account instead. I will set account null if they hit a 401
    loginUser,
    changePassword,
    changePasswordResponse,
    changePasswordLoading,
    initPasswordChange,
    initPasswordData,
    initPasswordLoading,
    // getMerchant,
    isAuthenticated: Boolean(user || data)
  };
}

export default useUser;

import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import {
  Container,
  Box,
  Button,
  Typography,
  Paper,
  Grid,
  Divider,
  Modal,
  Alert,
  CircularProgress
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
import {
  CosiResults,
  GetUserQuery,
  useChangeUserDataMutation,
  useGetUserQuery
} from 'generated/graphql';
import { MouseEvent, ReactNode, useEffect, useState } from 'react';
import HHIE from 'pages/patient/quizzes/HHIE';
import CCP from 'pages/patient/quizzes/CCP';
import { ControlledRadioButtonsGroup } from 'pages/patient/quizzes/COSI';
import { LoadingButton } from '@mui/lab';
import produce from 'immer';

function PatientTestScoreBox({
  label,
  description,
  retake = false,
  buttonLabel = 'Retake',
  buttonLabelRight,
  onClickLeftButton,
  onClickRightButton
}: {
  label: string | ReactNode;
  description?: string;
  retake?: boolean;
  buttonLabel?: string;
  buttonLabelRight?: string;
  onClickLeftButton?: (e: MouseEvent<HTMLButtonElement>) => void;
  onClickRightButton?: (e: MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <Paper>
      <Box sx={{ p: 2 }}>
        {label}
        <div>{description}</div>

        {retake && (
          <>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={buttonLabelRight ? 6 : 12}>
                <Button onClick={onClickLeftButton} variant="contained" fullWidth color="primary">
                  {buttonLabel}
                </Button>
              </Grid>
              {buttonLabelRight && (
                <Grid item xs={6}>
                  <Button
                    onClick={onClickRightButton}
                    variant="contained"
                    fullWidth
                    color="secondary"
                  >
                    {buttonLabelRight}
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Box>
    </Paper>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 800,
  margin: 'auto',
  overflow: 'scroll',
  maxHeight: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

export function PatientCosiResultsModal({
  cosiScore,
  open = false,
  onClose
}: {
  cosiScore?: CosiResults[] | null;
  open?: boolean;
  onClose: () => void;
}) {
  if (!cosiScore) return null;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 800,
          width: '100%',
          margin: 'auto',
          overflow: 'scroll',
          maxHeight: 550,
          bgcolor: 'background.default',
          boxShadow: 24,
          borderRadius: 2,
          p: 4
        }}
        // style={{
        //   maxWidth: 800,
        //   margin: 'auto',
        //   padding: 16,
        //   maxHeight: 400,
        //   overflow: 'scroll'
        // }}
      >
        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          User COSI Results
        </Typography>
        {cosiScore?.map((cosi, i) => (
          <Paper key={i} sx={{ mb: 2 }}>
            <Box p={2}>
              <div style={{ marginBottom: 12 }}>
                <Typography>
                  <strong>Category Selected:</strong>
                  <br /> {cosi?.categorySelected}
                </Typography>
              </div>
              <div style={{ marginBottom: 12 }}>
                <Typography>
                  <strong>Degree of Change:</strong>
                  <br /> {cosi?.degreeOfChange}
                </Typography>
              </div>
              <div style={{ marginBottom: 12 }}>
                <Typography>
                  <strong>Final Ability:</strong>
                  <br /> {(cosi as unknown as any)?.finalAbility ?? 'N/A'}
                </Typography>
              </div>
              <div style={{ marginBottom: 12 }}>
                <Typography>
                  <strong>Description:</strong>
                  <br /> {cosi?.text}
                </Typography>
              </div>
            </Box>
          </Paper>
        ))}
      </Box>
    </Modal>
  );
}

const answers = [
  {
    label: 'Worse',
    score: 0
  },
  {
    label: 'No Difference',
    score: 0
  },
  {
    label: 'Slightly Better',
    score: 0
  },
  {
    label: 'Better',
    score: 0
  },
  {
    label: 'Much Better',
    score: 0
  }
];

function FinalAbility({ onSaveResults }: { onSaveResults?: () => void }) {
  const { data } = useGetUserQuery();
  const [changeUserData, { loading }] = useChangeUserDataMutation();
  const [state, setState] = useState<GetUserQuery['getUser']['cosiScore'] | null>(null);

  const isFinalAbilityComplete = state?.filter((thing) => !thing?.finalAbility)?.length === 0;

  const handleSave = () => {
    changeUserData({
      variables: {
        // @ts-ignore
        cosiScore: state?.map((stateObj) => {
          const cleanedState = { ...stateObj };
          if (cleanedState?.__typename) {
            delete cleanedState.__typename;
          }
          return cleanedState;
        })
      },
      onCompleted: () => onSaveResults?.()
    });
  };

  useEffect(() => {
    if (data?.getUser.cosiScore) {
      setState(data.getUser.cosiScore);
    }
  }, [data]);

  if (loading) {
    return (
      <Box sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
        <h2>Saving Quiz Results...</h2>
        <CircularProgress />
      </Box>
    );
  }

  if (!data?.getUser.cosiScore)
    return (
      <Alert severity="info" variant="filled">
        You need to complete COSI
      </Alert>
    );

  return (
    <div>
      {state?.map((cosi, i) => (
        <div key={i} style={{ marginBottom: 16 }}>
          <Typography>Previous Answer: {cosi?.degreeOfChange}</Typography>
          <ControlledRadioButtonsGroup
            onSelection={(answerObj, question) => {
              setState(
                produce((draftState) => {
                  if (draftState?.[i]) {
                    (draftState[i] as CosiResults).finalAbility = answerObj.label;
                  }
                })
              );
            }}
            quizItem={{
              question: cosi?.categorySelected ?? ' ',
              answers
            }}
            value={state[i]?.finalAbility || ' '}
          />
        </div>
      ))}
      <LoadingButton
        disabled={!isFinalAbilityComplete}
        onClick={handleSave}
        loading={loading}
        color="primary"
        variant="contained"
      >
        Save
      </LoadingButton>
    </div>
  );
}

function PatientResults() {
  const { themeStretch } = useSettings();
  const [open, setOpen] = useState(false);
  const { data } = useGetUserQuery();
  const [HHIERetake, setHHIERetake] = useState(false);
  const [CCPRetake, setCCPRetake] = useState(false);
  const [takeFinalAbility, setTakeFinalAbility] = useState(false);
  return (
    <Page title="User: Test Results">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Test Results"
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'My Results' }]}
        />
        <Grid container spacing={2}>
          <Grid item sm={12} md={4}>
            <PatientTestScoreBox
              retake
              onClickLeftButton={() => {
                setCCPRetake(false);
                setTakeFinalAbility(false);
                setHHIERetake(true);
              }}
              label={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <span style={{ fontWeight: 700, fontSize: 20 }}>HHI</span>
                  <span style={{ fontWeight: 700, fontSize: 20 }}>{data?.getUser.hhieScore}</span>
                </div>
              }
            />
          </Grid>

          <Grid item sm={12} md={4}>
            <PatientTestScoreBox
              label={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <span style={{ fontWeight: 700, fontSize: 20 }}>COSI</span>
                </div>
              }
              retake
              buttonLabel={'View Results'}
              onClickLeftButton={() => setOpen(true)}
              buttonLabelRight="Take Final Ability"
              onClickRightButton={() => {
                setCCPRetake(false);
                setHHIERetake(false);
                setTakeFinalAbility(true);
              }}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <PatientTestScoreBox
              onClickLeftButton={() => {
                setHHIERetake(false);
                setTakeFinalAbility(false);
                setCCPRetake(true);
              }}
              retake
              label={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <span style={{ fontWeight: 700, fontSize: 20 }}>CCP</span>
                  <span style={{ fontWeight: 700, fontSize: 20 }}>{data?.getUser.ccpScore}</span>
                </div>
              }
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        {HHIERetake && (
          <Paper
            sx={{
              p: 2
            }}
          >
            <Typography sx={{ mb: 2 }} variant="h4">
              HHI Retake
            </Typography>
            <HHIE onSaveResults={() => setHHIERetake(false)} />
          </Paper>
        )}
        {CCPRetake && (
          <Paper sx={{ p: 2 }}>
            <Typography sx={{ mb: 2 }} variant="h4">
              CCP Retake
            </Typography>
            <CCP onSaveResults={() => setCCPRetake(false)} />
          </Paper>
        )}
        {takeFinalAbility && (
          <Paper sx={{ p: 2 }}>
            <FinalAbility onSaveResults={() => setTakeFinalAbility(false)} />
          </Paper>
        )}
        <PatientCosiResultsModal
          open={open}
          onClose={() => setOpen(false)}
          cosiScore={data?.getUser.cosiScore as CosiResults[]}
        />
      </Container>
    </Page>
  );
}

export default PatientResults;

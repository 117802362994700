import { useState, useEffect } from 'react';
// material
import { Container, Tab, Box, Tabs, Button, TextField, Typography } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import ProviderList from './ProviderList';
import CreateProviderModal from './CreateProviderModal';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

export default function MyProviders() {
  const { themeStretch } = useSettings();
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleRowEdit = (id: number, row: any) => {
    navigate(PATH_DASHBOARD.user.myProvidersEdit(id), {
      replace: true,
      state: { id, email: row.email, location: row.location }
    });
  };

  return (
    <Page title="User: Account Settings | Auditory Therapy">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Current Providers"
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'My Providers' }]}
        />
        <div style={{ textAlign: 'right', marginBottom: 16 }}>
          <Button variant="contained" onClick={() => setOpen(true)}>
            Add New Provider
          </Button>
        </div>
        <CreateProviderModal open={open} onModalClose={() => setOpen(false)} />
        <ProviderList onEdit={handleRowEdit} />
      </Container>
    </Page>
  );
}

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type CosiInput = {
  categorySelected: Scalars['String'];
  degreeOfChange: Scalars['String'];
  finalAbility?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  text: Scalars['String'];
};

export type CosiResults = {
  __typename?: 'CosiResults';
  categorySelected: Scalars['String'];
  degreeOfChange: Scalars['String'];
  finalAbility?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  text: Scalars['String'];
};

export type FamilyRecording = {
  __typename?: 'FamilyRecording';
  audioId: Scalars['Int'];
  awsFileKey: Scalars['String'];
  lessonId: Scalars['Int'];
  moduleId: Scalars['Int'];
};

export type FamilyRecordingInput = {
  audioId: Scalars['Int'];
  awsFileKey: Scalars['String'];
  lessonId: Scalars['Int'];
  moduleId: Scalars['Int'];
};

export type File = {
  __typename?: 'File';
  encoding: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
};

export type GenericResponse = {
  __typename?: 'GenericResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ModuleDataInput = {
  grade: Scalars['Float'];
  lessonId: Scalars['Int'];
  moduleId: Scalars['Int'];
  speakerType?: InputMaybe<Scalars['String']>;
  speechSpeed?: InputMaybe<Scalars['Float']>;
  volume: Scalars['Float'];
};

export type ModuleDataInput2 = {
  lessonId: Scalars['Int'];
  moduleId: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCompletedLesson: User;
  addRestrictedLesson: User;
  changeForgotPassword: GenericResponse;
  changeUserData: User;
  createPatient: User;
  createProvider: User;
  deletePatient: User;
  editPatient: User;
  editProvider: User;
  initForgotPassword: GenericResponse;
  saveFamilyRecording: User;
  singleUploadStream: File;
  updateSpeakerProficiency: GenericResponse;
  userLogin: Token;
  userLogout: GenericResponse;
};

export type MutationAddCompletedLessonArgs = {
  moduleData: ModuleDataInput;
};

export type MutationAddRestrictedLessonArgs = {
  moduleData: Array<ModuleDataInput2>;
  patientId: Scalars['Int'];
};

export type MutationChangeForgotPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationChangeUserDataArgs = {
  ccpScore?: InputMaybe<Scalars['Int']>;
  cosiScore?: InputMaybe<Array<InputMaybe<CosiInput>>>;
  email?: InputMaybe<Scalars['String']>;
  hhieScore?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
};

export type MutationCreatePatientArgs = {
  age: Scalars['Int'];
  educationLevel: Scalars['String'];
  email: Scalars['String'];
  ethnicity: Scalars['String'];
  gender: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
};

export type MutationCreateProviderArgs = {
  city: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  state: Scalars['String'];
};

export type MutationDeletePatientArgs = {
  id: Scalars['Int'];
};

export type MutationEditPatientArgs = {
  age?: InputMaybe<Scalars['Int']>;
  educationLevel?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  ethnicity?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type MutationEditProviderArgs = {
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  password?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type MutationInitForgotPasswordArgs = {
  email: Scalars['String'];
};

export type MutationSaveFamilyRecordingArgs = {
  familyRecordingData: Array<FamilyRecordingInput>;
  patientId: Scalars['Int'];
};

export type MutationSingleUploadStreamArgs = {
  file: Scalars['Upload'];
  fileName: Scalars['String'];
};

export type MutationUpdateSpeakerProficiencyArgs = {
  property: Scalars['String'];
  speakerType: Scalars['String'];
  value: Scalars['Float'];
};

export type MutationUserLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getFamilyRecording: Scalars['String'];
  getPatientById: User;
  getPatients?: Maybe<Array<User>>;
  getProviders?: Maybe<Array<User>>;
  getReports: Array<User>;
  getUser: User;
};

export type QueryGetFamilyRecordingArgs = {
  key: Scalars['String'];
};

export type QueryGetPatientByIdArgs = {
  patientId: Scalars['Int'];
};

export type QueryGetReportsArgs = {
  age: Scalars['String'];
  educationLevel: Scalars['String'];
  ethnicity: Scalars['String'];
  gender: Scalars['String'];
};

export type SavedModule = {
  __typename?: 'SavedModule';
  grade: Scalars['Float'];
  lessonId: Scalars['Int'];
  moduleId: Scalars['Int'];
  speakerType?: Maybe<Scalars['String']>;
  speechSpeed?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
  when?: Maybe<Scalars['String']>;
};

export type SavedModule2 = {
  __typename?: 'SavedModule2';
  grade: Scalars['Float'];
  lessonId: Scalars['Int'];
  moduleId: Scalars['Int'];
  speakerType?: Maybe<Scalars['String']>;
  speechSpeed?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
  when?: Maybe<Scalars['String']>;
};

export type SpeakerProficiency = {
  __typename?: 'SpeakerProficiency';
  multiSpeakerVolume?: Maybe<Scalars['Float']>;
  singleSpeakerVolume?: Maybe<Scalars['Float']>;
  speakerType?: Maybe<Scalars['String']>;
  speechSpeed?: Maybe<Scalars['Float']>;
};

export type Token = {
  __typename?: 'Token';
  token: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  age?: Maybe<Scalars['Int']>;
  ccpScore?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  completedLessons: Array<Maybe<SavedModule>>;
  cosiScore?: Maybe<Array<Maybe<CosiResults>>>;
  educationLevel?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  ethnicity?: Maybe<Scalars['String']>;
  familyRecordings?: Maybe<Array<Maybe<FamilyRecording>>>;
  gender?: Maybe<Scalars['String']>;
  hhieScore?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  lastLogin?: Maybe<Scalars['String']>;
  lessonPlanAccess: Array<Maybe<SavedModule2>>;
  name?: Maybe<Scalars['String']>;
  onboardingComplete: Scalars['Boolean'];
  speakerProficiency?: Maybe<Array<Maybe<SpeakerProficiency>>>;
  state?: Maybe<Scalars['String']>;
  userRole: UserRole;
};

export enum UserRole {
  Admin = 'ADMIN',
  All = 'ALL',
  Patient = 'PATIENT',
  Provider = 'PROVIDER'
}

export type UserFragmentFragment = {
  __typename?: 'User';
  id: string;
  userRole: UserRole;
  onboardingComplete: boolean;
  age?: number | null;
  educationLevel?: string | null;
  ethnicity?: string | null;
  gender?: string | null;
  email: string;
  name?: string | null;
  lastLogin?: string | null;
  city?: string | null;
  state?: string | null;
  hhieScore?: number | null;
  ccpScore?: number | null;
  cosiScore?: Array<{
    __typename?: 'CosiResults';
    id: number;
    categorySelected: string;
    text: string;
    degreeOfChange: string;
    finalAbility?: string | null;
  } | null> | null;
  lessonPlanAccess: Array<{
    __typename?: 'SavedModule2';
    lessonId: number;
    moduleId: number;
  } | null>;
  completedLessons: Array<{
    __typename?: 'SavedModule';
    lessonId: number;
    moduleId: number;
    grade: number;
    volume?: number | null;
    speakerType?: string | null;
    speechSpeed?: number | null;
    when?: string | null;
  } | null>;
  familyRecordings?: Array<{
    __typename?: 'FamilyRecording';
    awsFileKey: string;
    lessonId: number;
    moduleId: number;
    audioId: number;
  } | null> | null;
  speakerProficiency?: Array<{
    __typename?: 'SpeakerProficiency';
    speakerType?: string | null;
    singleSpeakerVolume?: number | null;
    multiSpeakerVolume?: number | null;
    speechSpeed?: number | null;
  } | null> | null;
};

export type CreateProviderMutationVariables = Exact<{
  createProviderEmail: Scalars['String'];
  createProviderPassword: Scalars['String'];
  createProviderCity: Scalars['String'];
  createProviderState: Scalars['String'];
}>;

export type CreateProviderMutation = {
  __typename?: 'Mutation';
  createProvider: {
    __typename?: 'User';
    id: string;
    userRole: UserRole;
    onboardingComplete: boolean;
    age?: number | null;
    educationLevel?: string | null;
    ethnicity?: string | null;
    gender?: string | null;
    email: string;
    name?: string | null;
    lastLogin?: string | null;
    city?: string | null;
    state?: string | null;
    hhieScore?: number | null;
    ccpScore?: number | null;
    cosiScore?: Array<{
      __typename?: 'CosiResults';
      id: number;
      categorySelected: string;
      text: string;
      degreeOfChange: string;
      finalAbility?: string | null;
    } | null> | null;
    lessonPlanAccess: Array<{
      __typename?: 'SavedModule2';
      lessonId: number;
      moduleId: number;
    } | null>;
    completedLessons: Array<{
      __typename?: 'SavedModule';
      lessonId: number;
      moduleId: number;
      grade: number;
      volume?: number | null;
      speakerType?: string | null;
      speechSpeed?: number | null;
      when?: string | null;
    } | null>;
    familyRecordings?: Array<{
      __typename?: 'FamilyRecording';
      awsFileKey: string;
      lessonId: number;
      moduleId: number;
      audioId: number;
    } | null> | null;
    speakerProficiency?: Array<{
      __typename?: 'SpeakerProficiency';
      speakerType?: string | null;
      singleSpeakerVolume?: number | null;
      multiSpeakerVolume?: number | null;
      speechSpeed?: number | null;
    } | null> | null;
  };
};

export type EditProviderMutationVariables = Exact<{
  editProviderId: Scalars['Int'];
  editProviderEmail?: InputMaybe<Scalars['String']>;
  editProviderPassword?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
}>;

export type EditProviderMutation = {
  __typename?: 'Mutation';
  editProvider: {
    __typename?: 'User';
    id: string;
    userRole: UserRole;
    onboardingComplete: boolean;
    age?: number | null;
    educationLevel?: string | null;
    ethnicity?: string | null;
    gender?: string | null;
    email: string;
    name?: string | null;
    lastLogin?: string | null;
    city?: string | null;
    state?: string | null;
    hhieScore?: number | null;
    ccpScore?: number | null;
    cosiScore?: Array<{
      __typename?: 'CosiResults';
      id: number;
      categorySelected: string;
      text: string;
      degreeOfChange: string;
      finalAbility?: string | null;
    } | null> | null;
    lessonPlanAccess: Array<{
      __typename?: 'SavedModule2';
      lessonId: number;
      moduleId: number;
    } | null>;
    completedLessons: Array<{
      __typename?: 'SavedModule';
      lessonId: number;
      moduleId: number;
      grade: number;
      volume?: number | null;
      speakerType?: string | null;
      speechSpeed?: number | null;
      when?: string | null;
    } | null>;
    familyRecordings?: Array<{
      __typename?: 'FamilyRecording';
      awsFileKey: string;
      lessonId: number;
      moduleId: number;
      audioId: number;
    } | null> | null;
    speakerProficiency?: Array<{
      __typename?: 'SpeakerProficiency';
      speakerType?: string | null;
      singleSpeakerVolume?: number | null;
      multiSpeakerVolume?: number | null;
      speechSpeed?: number | null;
    } | null> | null;
  };
};

export type GetProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type GetProvidersQuery = {
  __typename?: 'Query';
  getProviders?: Array<{
    __typename?: 'User';
    id: string;
    userRole: UserRole;
    onboardingComplete: boolean;
    age?: number | null;
    educationLevel?: string | null;
    ethnicity?: string | null;
    gender?: string | null;
    email: string;
    name?: string | null;
    lastLogin?: string | null;
    city?: string | null;
    state?: string | null;
    hhieScore?: number | null;
    ccpScore?: number | null;
    cosiScore?: Array<{
      __typename?: 'CosiResults';
      id: number;
      categorySelected: string;
      text: string;
      degreeOfChange: string;
      finalAbility?: string | null;
    } | null> | null;
    lessonPlanAccess: Array<{
      __typename?: 'SavedModule2';
      lessonId: number;
      moduleId: number;
    } | null>;
    completedLessons: Array<{
      __typename?: 'SavedModule';
      lessonId: number;
      moduleId: number;
      grade: number;
      volume?: number | null;
      speakerType?: string | null;
      speechSpeed?: number | null;
      when?: string | null;
    } | null>;
    familyRecordings?: Array<{
      __typename?: 'FamilyRecording';
      awsFileKey: string;
      lessonId: number;
      moduleId: number;
      audioId: number;
    } | null> | null;
    speakerProficiency?: Array<{
      __typename?: 'SpeakerProficiency';
      speakerType?: string | null;
      singleSpeakerVolume?: number | null;
      multiSpeakerVolume?: number | null;
      speechSpeed?: number | null;
    } | null> | null;
  }> | null;
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'User';
    id: string;
    userRole: UserRole;
    onboardingComplete: boolean;
    age?: number | null;
    educationLevel?: string | null;
    ethnicity?: string | null;
    gender?: string | null;
    email: string;
    name?: string | null;
    lastLogin?: string | null;
    city?: string | null;
    state?: string | null;
    hhieScore?: number | null;
    ccpScore?: number | null;
    cosiScore?: Array<{
      __typename?: 'CosiResults';
      id: number;
      categorySelected: string;
      text: string;
      degreeOfChange: string;
      finalAbility?: string | null;
    } | null> | null;
    lessonPlanAccess: Array<{
      __typename?: 'SavedModule2';
      lessonId: number;
      moduleId: number;
    } | null>;
    completedLessons: Array<{
      __typename?: 'SavedModule';
      lessonId: number;
      moduleId: number;
      grade: number;
      volume?: number | null;
      speakerType?: string | null;
      speechSpeed?: number | null;
      when?: string | null;
    } | null>;
    familyRecordings?: Array<{
      __typename?: 'FamilyRecording';
      awsFileKey: string;
      lessonId: number;
      moduleId: number;
      audioId: number;
    } | null> | null;
    speakerProficiency?: Array<{
      __typename?: 'SpeakerProficiency';
      speakerType?: string | null;
      singleSpeakerVolume?: number | null;
      multiSpeakerVolume?: number | null;
      speechSpeed?: number | null;
    } | null> | null;
  };
};

export type SignInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type SignInMutation = {
  __typename?: 'Mutation';
  userLogin: { __typename?: 'Token'; token: string };
};

export type UserLogoutMutationVariables = Exact<{ [key: string]: never }>;

export type UserLogoutMutation = {
  __typename?: 'Mutation';
  userLogout: { __typename?: 'GenericResponse'; success: boolean; message: string };
};

export type InitForgotPasswordMutationVariables = Exact<{
  initForgotPasswordEmail: Scalars['String'];
}>;

export type InitForgotPasswordMutation = {
  __typename?: 'Mutation';
  initForgotPassword: { __typename?: 'GenericResponse'; success: boolean; message: string };
};

export type ChangeForgotPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  changeForgotPasswordPassword: Scalars['String'];
}>;

export type ChangeForgotPasswordMutation = {
  __typename?: 'Mutation';
  changeForgotPassword: { __typename?: 'GenericResponse'; success: boolean; message: string };
};

export type ChangeUserDataMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  hhieScore?: InputMaybe<Scalars['Int']>;
  ccpScore?: InputMaybe<Scalars['Int']>;
  cosiScore?: InputMaybe<Array<InputMaybe<CosiInput>> | InputMaybe<CosiInput>>;
}>;

export type ChangeUserDataMutation = {
  __typename?: 'Mutation';
  changeUserData: {
    __typename?: 'User';
    id: string;
    userRole: UserRole;
    onboardingComplete: boolean;
    age?: number | null;
    educationLevel?: string | null;
    ethnicity?: string | null;
    gender?: string | null;
    email: string;
    name?: string | null;
    lastLogin?: string | null;
    city?: string | null;
    state?: string | null;
    hhieScore?: number | null;
    ccpScore?: number | null;
    cosiScore?: Array<{
      __typename?: 'CosiResults';
      id: number;
      categorySelected: string;
      text: string;
      degreeOfChange: string;
      finalAbility?: string | null;
    } | null> | null;
    lessonPlanAccess: Array<{
      __typename?: 'SavedModule2';
      lessonId: number;
      moduleId: number;
    } | null>;
    completedLessons: Array<{
      __typename?: 'SavedModule';
      lessonId: number;
      moduleId: number;
      grade: number;
      volume?: number | null;
      speakerType?: string | null;
      speechSpeed?: number | null;
      when?: string | null;
    } | null>;
    familyRecordings?: Array<{
      __typename?: 'FamilyRecording';
      awsFileKey: string;
      lessonId: number;
      moduleId: number;
      audioId: number;
    } | null> | null;
    speakerProficiency?: Array<{
      __typename?: 'SpeakerProficiency';
      speakerType?: string | null;
      singleSpeakerVolume?: number | null;
      multiSpeakerVolume?: number | null;
      speechSpeed?: number | null;
    } | null> | null;
  };
};

export type CreatePatientMutationVariables = Exact<{
  createPatientEmail: Scalars['String'];
  createPatientPassword: Scalars['String'];
  name: Scalars['String'];
  educationLevel: Scalars['String'];
  ethnicity: Scalars['String'];
  gender: Scalars['String'];
  age: Scalars['Int'];
}>;

export type CreatePatientMutation = {
  __typename?: 'Mutation';
  createPatient: {
    __typename?: 'User';
    id: string;
    userRole: UserRole;
    onboardingComplete: boolean;
    age?: number | null;
    educationLevel?: string | null;
    ethnicity?: string | null;
    gender?: string | null;
    email: string;
    name?: string | null;
    lastLogin?: string | null;
    city?: string | null;
    state?: string | null;
    hhieScore?: number | null;
    ccpScore?: number | null;
    cosiScore?: Array<{
      __typename?: 'CosiResults';
      id: number;
      categorySelected: string;
      text: string;
      degreeOfChange: string;
      finalAbility?: string | null;
    } | null> | null;
    lessonPlanAccess: Array<{
      __typename?: 'SavedModule2';
      lessonId: number;
      moduleId: number;
    } | null>;
    completedLessons: Array<{
      __typename?: 'SavedModule';
      lessonId: number;
      moduleId: number;
      grade: number;
      volume?: number | null;
      speakerType?: string | null;
      speechSpeed?: number | null;
      when?: string | null;
    } | null>;
    familyRecordings?: Array<{
      __typename?: 'FamilyRecording';
      awsFileKey: string;
      lessonId: number;
      moduleId: number;
      audioId: number;
    } | null> | null;
    speakerProficiency?: Array<{
      __typename?: 'SpeakerProficiency';
      speakerType?: string | null;
      singleSpeakerVolume?: number | null;
      multiSpeakerVolume?: number | null;
      speechSpeed?: number | null;
    } | null> | null;
  };
};

export type EditPatientMutationVariables = Exact<{
  editPatientId: Scalars['Int'];
  editPatientEmail?: InputMaybe<Scalars['String']>;
  editPatientPassword?: InputMaybe<Scalars['String']>;
  editPatientName?: InputMaybe<Scalars['String']>;
  editPatientEducationLevel?: InputMaybe<Scalars['String']>;
  editPatientEthnicity?: InputMaybe<Scalars['String']>;
  editPatientGender?: InputMaybe<Scalars['String']>;
  editPatientAge?: InputMaybe<Scalars['Int']>;
}>;

export type EditPatientMutation = {
  __typename?: 'Mutation';
  editPatient: {
    __typename?: 'User';
    id: string;
    userRole: UserRole;
    onboardingComplete: boolean;
    age?: number | null;
    educationLevel?: string | null;
    ethnicity?: string | null;
    gender?: string | null;
    email: string;
    name?: string | null;
    lastLogin?: string | null;
    city?: string | null;
    state?: string | null;
    hhieScore?: number | null;
    ccpScore?: number | null;
    cosiScore?: Array<{
      __typename?: 'CosiResults';
      id: number;
      categorySelected: string;
      text: string;
      degreeOfChange: string;
      finalAbility?: string | null;
    } | null> | null;
    lessonPlanAccess: Array<{
      __typename?: 'SavedModule2';
      lessonId: number;
      moduleId: number;
    } | null>;
    completedLessons: Array<{
      __typename?: 'SavedModule';
      lessonId: number;
      moduleId: number;
      grade: number;
      volume?: number | null;
      speakerType?: string | null;
      speechSpeed?: number | null;
      when?: string | null;
    } | null>;
    familyRecordings?: Array<{
      __typename?: 'FamilyRecording';
      awsFileKey: string;
      lessonId: number;
      moduleId: number;
      audioId: number;
    } | null> | null;
    speakerProficiency?: Array<{
      __typename?: 'SpeakerProficiency';
      speakerType?: string | null;
      singleSpeakerVolume?: number | null;
      multiSpeakerVolume?: number | null;
      speechSpeed?: number | null;
    } | null> | null;
  };
};

export type DeletePatientMutationVariables = Exact<{
  patientId: Scalars['Int'];
}>;

export type DeletePatientMutation = {
  __typename?: 'Mutation';
  deletePatient: {
    __typename?: 'User';
    id: string;
    userRole: UserRole;
    onboardingComplete: boolean;
    age?: number | null;
    educationLevel?: string | null;
    ethnicity?: string | null;
    gender?: string | null;
    email: string;
    name?: string | null;
    lastLogin?: string | null;
    city?: string | null;
    state?: string | null;
    hhieScore?: number | null;
    ccpScore?: number | null;
    cosiScore?: Array<{
      __typename?: 'CosiResults';
      id: number;
      categorySelected: string;
      text: string;
      degreeOfChange: string;
      finalAbility?: string | null;
    } | null> | null;
    lessonPlanAccess: Array<{
      __typename?: 'SavedModule2';
      lessonId: number;
      moduleId: number;
    } | null>;
    completedLessons: Array<{
      __typename?: 'SavedModule';
      lessonId: number;
      moduleId: number;
      grade: number;
      volume?: number | null;
      speakerType?: string | null;
      speechSpeed?: number | null;
      when?: string | null;
    } | null>;
    familyRecordings?: Array<{
      __typename?: 'FamilyRecording';
      awsFileKey: string;
      lessonId: number;
      moduleId: number;
      audioId: number;
    } | null> | null;
    speakerProficiency?: Array<{
      __typename?: 'SpeakerProficiency';
      speakerType?: string | null;
      singleSpeakerVolume?: number | null;
      multiSpeakerVolume?: number | null;
      speechSpeed?: number | null;
    } | null> | null;
  };
};

export type GetPatientsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPatientsQuery = {
  __typename?: 'Query';
  getPatients?: Array<{
    __typename?: 'User';
    id: string;
    userRole: UserRole;
    onboardingComplete: boolean;
    age?: number | null;
    educationLevel?: string | null;
    ethnicity?: string | null;
    gender?: string | null;
    email: string;
    name?: string | null;
    lastLogin?: string | null;
    city?: string | null;
    state?: string | null;
    hhieScore?: number | null;
    ccpScore?: number | null;
    cosiScore?: Array<{
      __typename?: 'CosiResults';
      id: number;
      categorySelected: string;
      text: string;
      degreeOfChange: string;
      finalAbility?: string | null;
    } | null> | null;
    lessonPlanAccess: Array<{
      __typename?: 'SavedModule2';
      lessonId: number;
      moduleId: number;
    } | null>;
    completedLessons: Array<{
      __typename?: 'SavedModule';
      lessonId: number;
      moduleId: number;
      grade: number;
      volume?: number | null;
      speakerType?: string | null;
      speechSpeed?: number | null;
      when?: string | null;
    } | null>;
    familyRecordings?: Array<{
      __typename?: 'FamilyRecording';
      awsFileKey: string;
      lessonId: number;
      moduleId: number;
      audioId: number;
    } | null> | null;
    speakerProficiency?: Array<{
      __typename?: 'SpeakerProficiency';
      speakerType?: string | null;
      singleSpeakerVolume?: number | null;
      multiSpeakerVolume?: number | null;
      speechSpeed?: number | null;
    } | null> | null;
  }> | null;
};

export type AddCompletedLessonMutationVariables = Exact<{
  moduleData: ModuleDataInput;
}>;

export type AddCompletedLessonMutation = {
  __typename?: 'Mutation';
  addCompletedLesson: {
    __typename?: 'User';
    id: string;
    userRole: UserRole;
    onboardingComplete: boolean;
    age?: number | null;
    educationLevel?: string | null;
    ethnicity?: string | null;
    gender?: string | null;
    email: string;
    name?: string | null;
    lastLogin?: string | null;
    city?: string | null;
    state?: string | null;
    hhieScore?: number | null;
    ccpScore?: number | null;
    cosiScore?: Array<{
      __typename?: 'CosiResults';
      id: number;
      categorySelected: string;
      text: string;
      degreeOfChange: string;
      finalAbility?: string | null;
    } | null> | null;
    lessonPlanAccess: Array<{
      __typename?: 'SavedModule2';
      lessonId: number;
      moduleId: number;
    } | null>;
    completedLessons: Array<{
      __typename?: 'SavedModule';
      lessonId: number;
      moduleId: number;
      grade: number;
      volume?: number | null;
      speakerType?: string | null;
      speechSpeed?: number | null;
      when?: string | null;
    } | null>;
    familyRecordings?: Array<{
      __typename?: 'FamilyRecording';
      awsFileKey: string;
      lessonId: number;
      moduleId: number;
      audioId: number;
    } | null> | null;
    speakerProficiency?: Array<{
      __typename?: 'SpeakerProficiency';
      speakerType?: string | null;
      singleSpeakerVolume?: number | null;
      multiSpeakerVolume?: number | null;
      speechSpeed?: number | null;
    } | null> | null;
  };
};

export type UpdateSpeakerProficiencyMutationVariables = Exact<{
  speakerType: Scalars['String'];
  property: Scalars['String'];
  value: Scalars['Float'];
}>;

export type UpdateSpeakerProficiencyMutation = {
  __typename?: 'Mutation';
  updateSpeakerProficiency: { __typename?: 'GenericResponse'; success: boolean; message: string };
};

export type GetReportsQueryVariables = Exact<{
  getReportsEducationLevel: Scalars['String'];
  getReportsEthnicity: Scalars['String'];
  getReportsGender: Scalars['String'];
  getReportsAge: Scalars['String'];
}>;

export type GetReportsQuery = {
  __typename?: 'Query';
  getReports: Array<{
    __typename?: 'User';
    id: string;
    userRole: UserRole;
    onboardingComplete: boolean;
    age?: number | null;
    educationLevel?: string | null;
    ethnicity?: string | null;
    gender?: string | null;
    email: string;
    name?: string | null;
    lastLogin?: string | null;
    city?: string | null;
    state?: string | null;
    hhieScore?: number | null;
    ccpScore?: number | null;
    cosiScore?: Array<{
      __typename?: 'CosiResults';
      id: number;
      categorySelected: string;
      text: string;
      degreeOfChange: string;
      finalAbility?: string | null;
    } | null> | null;
    lessonPlanAccess: Array<{
      __typename?: 'SavedModule2';
      lessonId: number;
      moduleId: number;
    } | null>;
    completedLessons: Array<{
      __typename?: 'SavedModule';
      lessonId: number;
      moduleId: number;
      grade: number;
      volume?: number | null;
      speakerType?: string | null;
      speechSpeed?: number | null;
      when?: string | null;
    } | null>;
    familyRecordings?: Array<{
      __typename?: 'FamilyRecording';
      awsFileKey: string;
      lessonId: number;
      moduleId: number;
      audioId: number;
    } | null> | null;
    speakerProficiency?: Array<{
      __typename?: 'SpeakerProficiency';
      speakerType?: string | null;
      singleSpeakerVolume?: number | null;
      multiSpeakerVolume?: number | null;
      speechSpeed?: number | null;
    } | null> | null;
  }>;
};

export type AddRestrictedLessonMutationVariables = Exact<{
  addRestrictedLessonModuleData: Array<ModuleDataInput2> | ModuleDataInput2;
  patientId: Scalars['Int'];
}>;

export type AddRestrictedLessonMutation = {
  __typename?: 'Mutation';
  addRestrictedLesson: {
    __typename?: 'User';
    id: string;
    userRole: UserRole;
    onboardingComplete: boolean;
    age?: number | null;
    educationLevel?: string | null;
    ethnicity?: string | null;
    gender?: string | null;
    email: string;
    name?: string | null;
    lastLogin?: string | null;
    city?: string | null;
    state?: string | null;
    hhieScore?: number | null;
    ccpScore?: number | null;
    cosiScore?: Array<{
      __typename?: 'CosiResults';
      id: number;
      categorySelected: string;
      text: string;
      degreeOfChange: string;
      finalAbility?: string | null;
    } | null> | null;
    lessonPlanAccess: Array<{
      __typename?: 'SavedModule2';
      lessonId: number;
      moduleId: number;
    } | null>;
    completedLessons: Array<{
      __typename?: 'SavedModule';
      lessonId: number;
      moduleId: number;
      grade: number;
      volume?: number | null;
      speakerType?: string | null;
      speechSpeed?: number | null;
      when?: string | null;
    } | null>;
    familyRecordings?: Array<{
      __typename?: 'FamilyRecording';
      awsFileKey: string;
      lessonId: number;
      moduleId: number;
      audioId: number;
    } | null> | null;
    speakerProficiency?: Array<{
      __typename?: 'SpeakerProficiency';
      speakerType?: string | null;
      singleSpeakerVolume?: number | null;
      multiSpeakerVolume?: number | null;
      speechSpeed?: number | null;
    } | null> | null;
  };
};

export type SingleUploadStreamMutationVariables = Exact<{
  file: Scalars['Upload'];
  fileName: Scalars['String'];
}>;

export type SingleUploadStreamMutation = {
  __typename?: 'Mutation';
  singleUploadStream: { __typename?: 'File'; filename: string; mimetype: string; encoding: string };
};

export type SaveFamilyRecordingMutationVariables = Exact<{
  familyRecordingData: Array<FamilyRecordingInput> | FamilyRecordingInput;
  patientId: Scalars['Int'];
}>;

export type SaveFamilyRecordingMutation = {
  __typename?: 'Mutation';
  saveFamilyRecording: {
    __typename?: 'User';
    id: string;
    userRole: UserRole;
    onboardingComplete: boolean;
    age?: number | null;
    educationLevel?: string | null;
    ethnicity?: string | null;
    gender?: string | null;
    email: string;
    name?: string | null;
    lastLogin?: string | null;
    city?: string | null;
    state?: string | null;
    hhieScore?: number | null;
    ccpScore?: number | null;
    cosiScore?: Array<{
      __typename?: 'CosiResults';
      id: number;
      categorySelected: string;
      text: string;
      degreeOfChange: string;
      finalAbility?: string | null;
    } | null> | null;
    lessonPlanAccess: Array<{
      __typename?: 'SavedModule2';
      lessonId: number;
      moduleId: number;
    } | null>;
    completedLessons: Array<{
      __typename?: 'SavedModule';
      lessonId: number;
      moduleId: number;
      grade: number;
      volume?: number | null;
      speakerType?: string | null;
      speechSpeed?: number | null;
      when?: string | null;
    } | null>;
    familyRecordings?: Array<{
      __typename?: 'FamilyRecording';
      awsFileKey: string;
      lessonId: number;
      moduleId: number;
      audioId: number;
    } | null> | null;
    speakerProficiency?: Array<{
      __typename?: 'SpeakerProficiency';
      speakerType?: string | null;
      singleSpeakerVolume?: number | null;
      multiSpeakerVolume?: number | null;
      speechSpeed?: number | null;
    } | null> | null;
  };
};

export type GetFamilyRecordingQueryVariables = Exact<{
  key: Scalars['String'];
}>;

export type GetFamilyRecordingQuery = { __typename?: 'Query'; getFamilyRecording: string };

export type GetPatientByIdQueryVariables = Exact<{
  getPatientByIdPatientId2: Scalars['Int'];
}>;

export type GetPatientByIdQuery = {
  __typename?: 'Query';
  getPatientById: {
    __typename?: 'User';
    id: string;
    userRole: UserRole;
    onboardingComplete: boolean;
    age?: number | null;
    educationLevel?: string | null;
    ethnicity?: string | null;
    gender?: string | null;
    email: string;
    name?: string | null;
    lastLogin?: string | null;
    city?: string | null;
    state?: string | null;
    hhieScore?: number | null;
    ccpScore?: number | null;
    cosiScore?: Array<{
      __typename?: 'CosiResults';
      id: number;
      categorySelected: string;
      text: string;
      degreeOfChange: string;
      finalAbility?: string | null;
    } | null> | null;
    lessonPlanAccess: Array<{
      __typename?: 'SavedModule2';
      lessonId: number;
      moduleId: number;
    } | null>;
    completedLessons: Array<{
      __typename?: 'SavedModule';
      lessonId: number;
      moduleId: number;
      grade: number;
      volume?: number | null;
      speakerType?: string | null;
      speechSpeed?: number | null;
      when?: string | null;
    } | null>;
    familyRecordings?: Array<{
      __typename?: 'FamilyRecording';
      awsFileKey: string;
      lessonId: number;
      moduleId: number;
      audioId: number;
    } | null> | null;
    speakerProficiency?: Array<{
      __typename?: 'SpeakerProficiency';
      speakerType?: string | null;
      singleSpeakerVolume?: number | null;
      multiSpeakerVolume?: number | null;
      speechSpeed?: number | null;
    } | null> | null;
  };
};

export const UserFragmentFragmentDoc = gql`
  fragment userFragment on User {
    id
    userRole
    onboardingComplete
    age
    educationLevel
    ethnicity
    gender
    email
    name
    lastLogin
    city
    state
    cosiScore {
      id
      categorySelected
      text
      degreeOfChange
      finalAbility
    }
    hhieScore
    ccpScore
    lessonPlanAccess {
      lessonId
      moduleId
    }
    completedLessons {
      lessonId
      moduleId
      grade
      volume
      speakerType
      speechSpeed
      when
    }
    familyRecordings {
      awsFileKey
      lessonId
      moduleId
      audioId
    }
    speakerProficiency {
      speakerType
      singleSpeakerVolume
      multiSpeakerVolume
      speechSpeed
    }
  }
`;
export const CreateProviderDocument = gql`
  mutation CreateProvider(
    $createProviderEmail: String!
    $createProviderPassword: String!
    $createProviderCity: String!
    $createProviderState: String!
  ) {
    createProvider(
      email: $createProviderEmail
      password: $createProviderPassword
      city: $createProviderCity
      state: $createProviderState
    ) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type CreateProviderMutationFn = Apollo.MutationFunction<
  CreateProviderMutation,
  CreateProviderMutationVariables
>;

/**
 * __useCreateProviderMutation__
 *
 * To run a mutation, you first call `useCreateProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProviderMutation, { data, loading, error }] = useCreateProviderMutation({
 *   variables: {
 *      createProviderEmail: // value for 'createProviderEmail'
 *      createProviderPassword: // value for 'createProviderPassword'
 *      createProviderCity: // value for 'createProviderCity'
 *      createProviderState: // value for 'createProviderState'
 *   },
 * });
 */
export function useCreateProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProviderMutation, CreateProviderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProviderMutation, CreateProviderMutationVariables>(
    CreateProviderDocument,
    options
  );
}
export type CreateProviderMutationHookResult = ReturnType<typeof useCreateProviderMutation>;
export type CreateProviderMutationResult = Apollo.MutationResult<CreateProviderMutation>;
export type CreateProviderMutationOptions = Apollo.BaseMutationOptions<
  CreateProviderMutation,
  CreateProviderMutationVariables
>;
export const EditProviderDocument = gql`
  mutation EditProvider(
    $editProviderId: Int!
    $editProviderEmail: String
    $editProviderPassword: String
    $city: String
    $state: String
  ) {
    editProvider(
      id: $editProviderId
      email: $editProviderEmail
      password: $editProviderPassword
      city: $city
      state: $state
    ) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type EditProviderMutationFn = Apollo.MutationFunction<
  EditProviderMutation,
  EditProviderMutationVariables
>;

/**
 * __useEditProviderMutation__
 *
 * To run a mutation, you first call `useEditProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProviderMutation, { data, loading, error }] = useEditProviderMutation({
 *   variables: {
 *      editProviderId: // value for 'editProviderId'
 *      editProviderEmail: // value for 'editProviderEmail'
 *      editProviderPassword: // value for 'editProviderPassword'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useEditProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<EditProviderMutation, EditProviderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditProviderMutation, EditProviderMutationVariables>(
    EditProviderDocument,
    options
  );
}
export type EditProviderMutationHookResult = ReturnType<typeof useEditProviderMutation>;
export type EditProviderMutationResult = Apollo.MutationResult<EditProviderMutation>;
export type EditProviderMutationOptions = Apollo.BaseMutationOptions<
  EditProviderMutation,
  EditProviderMutationVariables
>;
export const GetProvidersDocument = gql`
  query GetProviders {
    getProviders {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useGetProvidersQuery__
 *
 * To run a query within a React component, call `useGetProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProvidersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProvidersQuery, GetProvidersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProvidersQuery, GetProvidersQueryVariables>(
    GetProvidersDocument,
    options
  );
}
export function useGetProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProvidersQuery, GetProvidersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProvidersQuery, GetProvidersQueryVariables>(
    GetProvidersDocument,
    options
  );
}
export type GetProvidersQueryHookResult = ReturnType<typeof useGetProvidersQuery>;
export type GetProvidersLazyQueryHookResult = ReturnType<typeof useGetProvidersLazyQuery>;
export type GetProvidersQueryResult = Apollo.QueryResult<
  GetProvidersQuery,
  GetProvidersQueryVariables
>;
export const GetUserDocument = gql`
  query GetUser {
    getUser {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const SignInDocument = gql`
  mutation SignIn($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      token
    }
  }
`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<
  SignInMutation,
  SignInMutationVariables
>;
export const UserLogoutDocument = gql`
  mutation UserLogout {
    userLogout {
      success
      message
    }
  }
`;
export type UserLogoutMutationFn = Apollo.MutationFunction<
  UserLogoutMutation,
  UserLogoutMutationVariables
>;

/**
 * __useUserLogoutMutation__
 *
 * To run a mutation, you first call `useUserLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLogoutMutation, { data, loading, error }] = useUserLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<UserLogoutMutation, UserLogoutMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserLogoutMutation, UserLogoutMutationVariables>(
    UserLogoutDocument,
    options
  );
}
export type UserLogoutMutationHookResult = ReturnType<typeof useUserLogoutMutation>;
export type UserLogoutMutationResult = Apollo.MutationResult<UserLogoutMutation>;
export type UserLogoutMutationOptions = Apollo.BaseMutationOptions<
  UserLogoutMutation,
  UserLogoutMutationVariables
>;
export const InitForgotPasswordDocument = gql`
  mutation InitForgotPassword($initForgotPasswordEmail: String!) {
    initForgotPassword(email: $initForgotPasswordEmail) {
      success
      message
    }
  }
`;
export type InitForgotPasswordMutationFn = Apollo.MutationFunction<
  InitForgotPasswordMutation,
  InitForgotPasswordMutationVariables
>;

/**
 * __useInitForgotPasswordMutation__
 *
 * To run a mutation, you first call `useInitForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initForgotPasswordMutation, { data, loading, error }] = useInitForgotPasswordMutation({
 *   variables: {
 *      initForgotPasswordEmail: // value for 'initForgotPasswordEmail'
 *   },
 * });
 */
export function useInitForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitForgotPasswordMutation,
    InitForgotPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InitForgotPasswordMutation, InitForgotPasswordMutationVariables>(
    InitForgotPasswordDocument,
    options
  );
}
export type InitForgotPasswordMutationHookResult = ReturnType<typeof useInitForgotPasswordMutation>;
export type InitForgotPasswordMutationResult = Apollo.MutationResult<InitForgotPasswordMutation>;
export type InitForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  InitForgotPasswordMutation,
  InitForgotPasswordMutationVariables
>;
export const ChangeForgotPasswordDocument = gql`
  mutation ChangeForgotPassword($token: String!, $changeForgotPasswordPassword: String!) {
    changeForgotPassword(token: $token, password: $changeForgotPasswordPassword) {
      success
      message
    }
  }
`;
export type ChangeForgotPasswordMutationFn = Apollo.MutationFunction<
  ChangeForgotPasswordMutation,
  ChangeForgotPasswordMutationVariables
>;

/**
 * __useChangeForgotPasswordMutation__
 *
 * To run a mutation, you first call `useChangeForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeForgotPasswordMutation, { data, loading, error }] = useChangeForgotPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      changeForgotPasswordPassword: // value for 'changeForgotPasswordPassword'
 *   },
 * });
 */
export function useChangeForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeForgotPasswordMutation,
    ChangeForgotPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeForgotPasswordMutation, ChangeForgotPasswordMutationVariables>(
    ChangeForgotPasswordDocument,
    options
  );
}
export type ChangeForgotPasswordMutationHookResult = ReturnType<
  typeof useChangeForgotPasswordMutation
>;
export type ChangeForgotPasswordMutationResult =
  Apollo.MutationResult<ChangeForgotPasswordMutation>;
export type ChangeForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangeForgotPasswordMutation,
  ChangeForgotPasswordMutationVariables
>;
export const ChangeUserDataDocument = gql`
  mutation ChangeUserData(
    $email: String
    $password: String
    $hhieScore: Int
    $ccpScore: Int
    $cosiScore: [CosiInput]
  ) {
    changeUserData(
      email: $email
      password: $password
      hhieScore: $hhieScore
      ccpScore: $ccpScore
      cosiScore: $cosiScore
    ) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type ChangeUserDataMutationFn = Apollo.MutationFunction<
  ChangeUserDataMutation,
  ChangeUserDataMutationVariables
>;

/**
 * __useChangeUserDataMutation__
 *
 * To run a mutation, you first call `useChangeUserDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserDataMutation, { data, loading, error }] = useChangeUserDataMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      hhieScore: // value for 'hhieScore'
 *      ccpScore: // value for 'ccpScore'
 *      cosiScore: // value for 'cosiScore'
 *   },
 * });
 */
export function useChangeUserDataMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeUserDataMutation, ChangeUserDataMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeUserDataMutation, ChangeUserDataMutationVariables>(
    ChangeUserDataDocument,
    options
  );
}
export type ChangeUserDataMutationHookResult = ReturnType<typeof useChangeUserDataMutation>;
export type ChangeUserDataMutationResult = Apollo.MutationResult<ChangeUserDataMutation>;
export type ChangeUserDataMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserDataMutation,
  ChangeUserDataMutationVariables
>;
export const CreatePatientDocument = gql`
  mutation CreatePatient(
    $createPatientEmail: String!
    $createPatientPassword: String!
    $name: String!
    $educationLevel: String!
    $ethnicity: String!
    $gender: String!
    $age: Int!
  ) {
    createPatient(
      email: $createPatientEmail
      password: $createPatientPassword
      name: $name
      educationLevel: $educationLevel
      ethnicity: $ethnicity
      gender: $gender
      age: $age
    ) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type CreatePatientMutationFn = Apollo.MutationFunction<
  CreatePatientMutation,
  CreatePatientMutationVariables
>;

/**
 * __useCreatePatientMutation__
 *
 * To run a mutation, you first call `useCreatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientMutation, { data, loading, error }] = useCreatePatientMutation({
 *   variables: {
 *      createPatientEmail: // value for 'createPatientEmail'
 *      createPatientPassword: // value for 'createPatientPassword'
 *      name: // value for 'name'
 *      educationLevel: // value for 'educationLevel'
 *      ethnicity: // value for 'ethnicity'
 *      gender: // value for 'gender'
 *      age: // value for 'age'
 *   },
 * });
 */
export function useCreatePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePatientMutation, CreatePatientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePatientMutation, CreatePatientMutationVariables>(
    CreatePatientDocument,
    options
  );
}
export type CreatePatientMutationHookResult = ReturnType<typeof useCreatePatientMutation>;
export type CreatePatientMutationResult = Apollo.MutationResult<CreatePatientMutation>;
export type CreatePatientMutationOptions = Apollo.BaseMutationOptions<
  CreatePatientMutation,
  CreatePatientMutationVariables
>;
export const EditPatientDocument = gql`
  mutation EditPatient(
    $editPatientId: Int!
    $editPatientEmail: String
    $editPatientPassword: String
    $editPatientName: String
    $editPatientEducationLevel: String
    $editPatientEthnicity: String
    $editPatientGender: String
    $editPatientAge: Int
  ) {
    editPatient(
      id: $editPatientId
      email: $editPatientEmail
      password: $editPatientPassword
      name: $editPatientName
      educationLevel: $editPatientEducationLevel
      ethnicity: $editPatientEthnicity
      gender: $editPatientGender
      age: $editPatientAge
    ) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type EditPatientMutationFn = Apollo.MutationFunction<
  EditPatientMutation,
  EditPatientMutationVariables
>;

/**
 * __useEditPatientMutation__
 *
 * To run a mutation, you first call `useEditPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPatientMutation, { data, loading, error }] = useEditPatientMutation({
 *   variables: {
 *      editPatientId: // value for 'editPatientId'
 *      editPatientEmail: // value for 'editPatientEmail'
 *      editPatientPassword: // value for 'editPatientPassword'
 *      editPatientName: // value for 'editPatientName'
 *      editPatientEducationLevel: // value for 'editPatientEducationLevel'
 *      editPatientEthnicity: // value for 'editPatientEthnicity'
 *      editPatientGender: // value for 'editPatientGender'
 *      editPatientAge: // value for 'editPatientAge'
 *   },
 * });
 */
export function useEditPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<EditPatientMutation, EditPatientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditPatientMutation, EditPatientMutationVariables>(
    EditPatientDocument,
    options
  );
}
export type EditPatientMutationHookResult = ReturnType<typeof useEditPatientMutation>;
export type EditPatientMutationResult = Apollo.MutationResult<EditPatientMutation>;
export type EditPatientMutationOptions = Apollo.BaseMutationOptions<
  EditPatientMutation,
  EditPatientMutationVariables
>;
export const DeletePatientDocument = gql`
  mutation DeletePatient($patientId: Int!) {
    deletePatient(id: $patientId) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type DeletePatientMutationFn = Apollo.MutationFunction<
  DeletePatientMutation,
  DeletePatientMutationVariables
>;

/**
 * __useDeletePatientMutation__
 *
 * To run a mutation, you first call `useDeletePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientMutation, { data, loading, error }] = useDeletePatientMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useDeletePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePatientMutation, DeletePatientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePatientMutation, DeletePatientMutationVariables>(
    DeletePatientDocument,
    options
  );
}
export type DeletePatientMutationHookResult = ReturnType<typeof useDeletePatientMutation>;
export type DeletePatientMutationResult = Apollo.MutationResult<DeletePatientMutation>;
export type DeletePatientMutationOptions = Apollo.BaseMutationOptions<
  DeletePatientMutation,
  DeletePatientMutationVariables
>;
export const GetPatientsDocument = gql`
  query GetPatients {
    getPatients {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useGetPatientsQuery__
 *
 * To run a query within a React component, call `useGetPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPatientsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, options);
}
export function useGetPatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPatientsQuery, GetPatientsQueryVariables>(
    GetPatientsDocument,
    options
  );
}
export type GetPatientsQueryHookResult = ReturnType<typeof useGetPatientsQuery>;
export type GetPatientsLazyQueryHookResult = ReturnType<typeof useGetPatientsLazyQuery>;
export type GetPatientsQueryResult = Apollo.QueryResult<
  GetPatientsQuery,
  GetPatientsQueryVariables
>;
export const AddCompletedLessonDocument = gql`
  mutation AddCompletedLesson($moduleData: ModuleDataInput!) {
    addCompletedLesson(moduleData: $moduleData) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type AddCompletedLessonMutationFn = Apollo.MutationFunction<
  AddCompletedLessonMutation,
  AddCompletedLessonMutationVariables
>;

/**
 * __useAddCompletedLessonMutation__
 *
 * To run a mutation, you first call `useAddCompletedLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompletedLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompletedLessonMutation, { data, loading, error }] = useAddCompletedLessonMutation({
 *   variables: {
 *      moduleData: // value for 'moduleData'
 *   },
 * });
 */
export function useAddCompletedLessonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCompletedLessonMutation,
    AddCompletedLessonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCompletedLessonMutation, AddCompletedLessonMutationVariables>(
    AddCompletedLessonDocument,
    options
  );
}
export type AddCompletedLessonMutationHookResult = ReturnType<typeof useAddCompletedLessonMutation>;
export type AddCompletedLessonMutationResult = Apollo.MutationResult<AddCompletedLessonMutation>;
export type AddCompletedLessonMutationOptions = Apollo.BaseMutationOptions<
  AddCompletedLessonMutation,
  AddCompletedLessonMutationVariables
>;
export const UpdateSpeakerProficiencyDocument = gql`
  mutation UpdateSpeakerProficiency($speakerType: String!, $property: String!, $value: Float!) {
    updateSpeakerProficiency(speakerType: $speakerType, property: $property, value: $value) {
      success
      message
    }
  }
`;
export type UpdateSpeakerProficiencyMutationFn = Apollo.MutationFunction<
  UpdateSpeakerProficiencyMutation,
  UpdateSpeakerProficiencyMutationVariables
>;

/**
 * __useUpdateSpeakerProficiencyMutation__
 *
 * To run a mutation, you first call `useUpdateSpeakerProficiencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpeakerProficiencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpeakerProficiencyMutation, { data, loading, error }] = useUpdateSpeakerProficiencyMutation({
 *   variables: {
 *      speakerType: // value for 'speakerType'
 *      property: // value for 'property'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateSpeakerProficiencyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSpeakerProficiencyMutation,
    UpdateSpeakerProficiencyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSpeakerProficiencyMutation,
    UpdateSpeakerProficiencyMutationVariables
  >(UpdateSpeakerProficiencyDocument, options);
}
export type UpdateSpeakerProficiencyMutationHookResult = ReturnType<
  typeof useUpdateSpeakerProficiencyMutation
>;
export type UpdateSpeakerProficiencyMutationResult =
  Apollo.MutationResult<UpdateSpeakerProficiencyMutation>;
export type UpdateSpeakerProficiencyMutationOptions = Apollo.BaseMutationOptions<
  UpdateSpeakerProficiencyMutation,
  UpdateSpeakerProficiencyMutationVariables
>;
export const GetReportsDocument = gql`
  query GetReports(
    $getReportsEducationLevel: String!
    $getReportsEthnicity: String!
    $getReportsGender: String!
    $getReportsAge: String!
  ) {
    getReports(
      educationLevel: $getReportsEducationLevel
      ethnicity: $getReportsEthnicity
      gender: $getReportsGender
      age: $getReportsAge
    ) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useGetReportsQuery__
 *
 * To run a query within a React component, call `useGetReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportsQuery({
 *   variables: {
 *      getReportsEducationLevel: // value for 'getReportsEducationLevel'
 *      getReportsEthnicity: // value for 'getReportsEthnicity'
 *      getReportsGender: // value for 'getReportsGender'
 *      getReportsAge: // value for 'getReportsAge'
 *   },
 * });
 */
export function useGetReportsQuery(
  baseOptions: Apollo.QueryHookOptions<GetReportsQuery, GetReportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options);
}
export function useGetReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReportsQuery, GetReportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReportsQuery, GetReportsQueryVariables>(
    GetReportsDocument,
    options
  );
}
export type GetReportsQueryHookResult = ReturnType<typeof useGetReportsQuery>;
export type GetReportsLazyQueryHookResult = ReturnType<typeof useGetReportsLazyQuery>;
export type GetReportsQueryResult = Apollo.QueryResult<GetReportsQuery, GetReportsQueryVariables>;
export const AddRestrictedLessonDocument = gql`
  mutation AddRestrictedLesson(
    $addRestrictedLessonModuleData: [ModuleDataInput2!]!
    $patientId: Int!
  ) {
    addRestrictedLesson(moduleData: $addRestrictedLessonModuleData, patientId: $patientId) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type AddRestrictedLessonMutationFn = Apollo.MutationFunction<
  AddRestrictedLessonMutation,
  AddRestrictedLessonMutationVariables
>;

/**
 * __useAddRestrictedLessonMutation__
 *
 * To run a mutation, you first call `useAddRestrictedLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRestrictedLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRestrictedLessonMutation, { data, loading, error }] = useAddRestrictedLessonMutation({
 *   variables: {
 *      addRestrictedLessonModuleData: // value for 'addRestrictedLessonModuleData'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useAddRestrictedLessonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRestrictedLessonMutation,
    AddRestrictedLessonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddRestrictedLessonMutation, AddRestrictedLessonMutationVariables>(
    AddRestrictedLessonDocument,
    options
  );
}
export type AddRestrictedLessonMutationHookResult = ReturnType<
  typeof useAddRestrictedLessonMutation
>;
export type AddRestrictedLessonMutationResult = Apollo.MutationResult<AddRestrictedLessonMutation>;
export type AddRestrictedLessonMutationOptions = Apollo.BaseMutationOptions<
  AddRestrictedLessonMutation,
  AddRestrictedLessonMutationVariables
>;
export const SingleUploadStreamDocument = gql`
  mutation SingleUploadStream($file: Upload!, $fileName: String!) {
    singleUploadStream(file: $file, fileName: $fileName) {
      filename
      mimetype
      encoding
    }
  }
`;
export type SingleUploadStreamMutationFn = Apollo.MutationFunction<
  SingleUploadStreamMutation,
  SingleUploadStreamMutationVariables
>;

/**
 * __useSingleUploadStreamMutation__
 *
 * To run a mutation, you first call `useSingleUploadStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSingleUploadStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [singleUploadStreamMutation, { data, loading, error }] = useSingleUploadStreamMutation({
 *   variables: {
 *      file: // value for 'file'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useSingleUploadStreamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SingleUploadStreamMutation,
    SingleUploadStreamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SingleUploadStreamMutation, SingleUploadStreamMutationVariables>(
    SingleUploadStreamDocument,
    options
  );
}
export type SingleUploadStreamMutationHookResult = ReturnType<typeof useSingleUploadStreamMutation>;
export type SingleUploadStreamMutationResult = Apollo.MutationResult<SingleUploadStreamMutation>;
export type SingleUploadStreamMutationOptions = Apollo.BaseMutationOptions<
  SingleUploadStreamMutation,
  SingleUploadStreamMutationVariables
>;
export const SaveFamilyRecordingDocument = gql`
  mutation SaveFamilyRecording($familyRecordingData: [FamilyRecordingInput!]!, $patientId: Int!) {
    saveFamilyRecording(familyRecordingData: $familyRecordingData, patientId: $patientId) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type SaveFamilyRecordingMutationFn = Apollo.MutationFunction<
  SaveFamilyRecordingMutation,
  SaveFamilyRecordingMutationVariables
>;

/**
 * __useSaveFamilyRecordingMutation__
 *
 * To run a mutation, you first call `useSaveFamilyRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFamilyRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFamilyRecordingMutation, { data, loading, error }] = useSaveFamilyRecordingMutation({
 *   variables: {
 *      familyRecordingData: // value for 'familyRecordingData'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useSaveFamilyRecordingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveFamilyRecordingMutation,
    SaveFamilyRecordingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveFamilyRecordingMutation, SaveFamilyRecordingMutationVariables>(
    SaveFamilyRecordingDocument,
    options
  );
}
export type SaveFamilyRecordingMutationHookResult = ReturnType<
  typeof useSaveFamilyRecordingMutation
>;
export type SaveFamilyRecordingMutationResult = Apollo.MutationResult<SaveFamilyRecordingMutation>;
export type SaveFamilyRecordingMutationOptions = Apollo.BaseMutationOptions<
  SaveFamilyRecordingMutation,
  SaveFamilyRecordingMutationVariables
>;
export const GetFamilyRecordingDocument = gql`
  query GetFamilyRecording($key: String!) {
    getFamilyRecording(key: $key)
  }
`;

/**
 * __useGetFamilyRecordingQuery__
 *
 * To run a query within a React component, call `useGetFamilyRecordingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFamilyRecordingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFamilyRecordingQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetFamilyRecordingQuery(
  baseOptions: Apollo.QueryHookOptions<GetFamilyRecordingQuery, GetFamilyRecordingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFamilyRecordingQuery, GetFamilyRecordingQueryVariables>(
    GetFamilyRecordingDocument,
    options
  );
}
export function useGetFamilyRecordingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFamilyRecordingQuery,
    GetFamilyRecordingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFamilyRecordingQuery, GetFamilyRecordingQueryVariables>(
    GetFamilyRecordingDocument,
    options
  );
}
export type GetFamilyRecordingQueryHookResult = ReturnType<typeof useGetFamilyRecordingQuery>;
export type GetFamilyRecordingLazyQueryHookResult = ReturnType<
  typeof useGetFamilyRecordingLazyQuery
>;
export type GetFamilyRecordingQueryResult = Apollo.QueryResult<
  GetFamilyRecordingQuery,
  GetFamilyRecordingQueryVariables
>;
export const GetPatientByIdDocument = gql`
  query GetPatientById($getPatientByIdPatientId2: Int!) {
    getPatientById(patientId: $getPatientByIdPatientId2) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useGetPatientByIdQuery__
 *
 * To run a query within a React component, call `useGetPatientByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientByIdQuery({
 *   variables: {
 *      getPatientByIdPatientId2: // value for 'getPatientByIdPatientId2'
 *   },
 * });
 */
export function useGetPatientByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetPatientByIdQuery, GetPatientByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPatientByIdQuery, GetPatientByIdQueryVariables>(
    GetPatientByIdDocument,
    options
  );
}
export function useGetPatientByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientByIdQuery, GetPatientByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPatientByIdQuery, GetPatientByIdQueryVariables>(
    GetPatientByIdDocument,
    options
  );
}
export type GetPatientByIdQueryHookResult = ReturnType<typeof useGetPatientByIdQuery>;
export type GetPatientByIdLazyQueryHookResult = ReturnType<typeof useGetPatientByIdLazyQuery>;
export type GetPatientByIdQueryResult = Apollo.QueryResult<
  GetPatientByIdQuery,
  GetPatientByIdQueryVariables
>;

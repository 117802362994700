import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// material
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { useCallback } from 'react';
import { FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import { EDIT_PROVIDER } from 'graphql/user/user.mutations';
import { pickBy } from 'lodash';
import PatientEditForm from 'components/_dashboard/user/patients/PatientEditForm';
import { useEditPatientMutation, User } from 'generated/graphql';

// ----------------------------------------------------------------------

interface LocationState {
  id: number;
  row: User;
}

export default function PatientEdit() {
  const { themeStretch } = useSettings();
  const { pathname, state } = useLocation();
  const { orderId = '' } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [editPatient, { data, loading }] = useEditPatientMutation();

  const isEdit = true;
  const patient = state as User;
  const isLoading = false;

  const handleUpdatingOrder = useCallback(
    async (values: any, formikHelpers: FormikHelpers<any>) => {
      editPatient({
        variables: {
          editPatientId: Number(values.id),
          ...pickBy({
            editPatientAge: values.age,
            editPatientEducationLevel: values.educationLevel,
            editPatientEmail: values.email,
            editPatientEthnicity: values.ethnicity,
            editPatientGender: values.gender,
            editPatientName: values.name,
            editPatientPassword: values.password
          })
        }
      })
        .then(() => {
          enqueueSnackbar('Patient Successfully Updated', { variant: 'success' });
          navigate(PATH_DASHBOARD.user.patients);
        })
        .catch((e) => enqueueSnackbar(e.message, { variant: 'error' }));
    },
    [navigate, enqueueSnackbar, orderId]
  );

  const handleSubmit = useCallback(
    async (values: any, formikHelpers: FormikHelpers<any>) => {
      handleUpdatingOrder(values, formikHelpers);
    },
    [handleUpdatingOrder]
  );

  return (
    <Page title="Patient: Edit Patient">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create a new order' : 'Edit Patient'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'My Patients', href: PATH_DASHBOARD.user.patients },
            { name: !isEdit ? 'New Patient' : patient.id }
          ]}
        />

        <PatientEditForm isEdit patient={patient} onSubmit={handleSubmit} isLoading={loading} />
      </Container>
    </Page>
  );
}

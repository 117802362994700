import { useQuery } from '@apollo/client';
import LoadingScreen from 'components/LoadingScreen';
import { useGetUserQuery, UserRole } from 'generated/graphql';
import PatientOnboarding from 'pages/patient/PatientOnboarding';
import { useState, ReactNode } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'routes/paths';
// pages
import Login from '../pages/authentication/Login';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { loading, data, error } = useGetUserQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  const navigate = useNavigate();

  if (loading) {
    return <LoadingScreen />;
  }

  if (data?.getUser?.userRole === UserRole.Patient && !data?.getUser?.onboardingComplete) {
    return <PatientOnboarding />;
  }

  if (!data || error) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
      navigate(PATH_DASHBOARD.root);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}

import { useState } from 'react';
// material
import { Alert, Button, TextField, Typography } from '@mui/material';
import BasicModal from './BasicModal';
import { useMutation } from '@apollo/client';
import { CREATE_PROVIDER } from 'graphql/user/user.mutations';
import { LoadingButton } from '@mui/lab';
import { GetProvidersDocument } from 'generated/graphql';

// ----------------------------------------------------------------------

export default function CreateProviderModal({
  open,
  onModalClose
}: {
  open: boolean;
  onModalClose: () => void;
}) {
  const [createProvider, { data, error, loading: creatingProviderLoading }] = useMutation(
    CREATE_PROVIDER,
    {
      refetchQueries: [GetProvidersDocument]
    }
  );
  const [formState, setFormState] = useState<{
    email: String;
    city: String;
    state: String;
    password: String;
  }>({
    email: '',
    state: '',
    city: '',
    password: ''
  });

  const handleProviderCreation = () => {
    // createProviderEmail: String!, $createProviderPassword: String!, $locationName:
    createProvider({
      variables: {
        createProviderEmail: formState.email,
        createProviderPassword: formState.password,
        createProviderCity: formState.city,
        createProviderState: formState.state
      }
    }).then(() => onModalClose());
  };

  const handleSimpleForm = (e: any) => {
    if (!e) return;
    const name = e.target.name;
    const value = e.target.value;

    if (!name) {
      throw new Error('Name on input cannot be blank');
    }

    setFormState((prevFormState) => ({
      ...prevFormState,
      [name]: value
    }));
  };

  return (
    <BasicModal open={open} onClose={onModalClose}>
      {error && <Alert severity="error">{error.message ?? 'Errors in request'}</Alert>}
      <Typography variant="h3">Create A Provider</Typography>
      <TextField
        onChange={handleSimpleForm}
        name="city"
        placeholder="City"
        margin="dense"
        fullWidth
        value={formState.city}
      />
      <TextField
        onChange={handleSimpleForm}
        name="state"
        placeholder="State"
        margin="dense"
        fullWidth
        value={formState.state}
      />
      <TextField
        onChange={handleSimpleForm}
        name="email"
        placeholder="Email"
        margin="dense"
        fullWidth
        value={formState.email}
      />
      <TextField
        onChange={handleSimpleForm}
        name="password"
        placeholder="Password"
        margin="dense"
        fullWidth
        value={formState.password}
      />
      <LoadingButton
        loading={creatingProviderLoading}
        variant="contained"
        onClick={handleProviderCreation}
      >
        Create Provider
      </LoadingButton>
    </BasicModal>
  );
}

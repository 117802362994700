import { gql } from '@apollo/client';
import { userFragment } from './user.fragments';

export const USER_LOGIN = gql`
  ${userFragment}
  mutation SignIn($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      ...userFragment
    }
  }
`;

export const INIT_FORGOT_PASSWORD = gql`
  mutation InitForgotPassword($email: String!) {
    initForgotPassword(email: $email) {
      success
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangeForgotPassword($token: String!, $password: String!) {
    changeForgotPassword(token: $token, password: $password) {
      success
      message
    }
  }
`;

export const CREATE_PROVIDER = gql`
  ${userFragment}
  mutation CreateProvider(
    $createProviderEmail: String!
    $createProviderPassword: String!
    $createProviderCity: String!
    $createProviderState: String!
  ) {
    createProvider(
      email: $createProviderEmail
      password: $createProviderPassword
      city: $createProviderCity
      state: $createProviderState
    ) {
      ...userFragment
    }
  }
`;

export const EDIT_PROVIDER = gql`
  ${userFragment}
  mutation EditProvider(
    $editProviderId: Int!
    $editProviderEmail: String
    $editProviderPassword: String
    $city: String
    $state: String
  ) {
    editProvider(
      id: $editProviderId
      email: $editProviderEmail
      password: $editProviderPassword
      city: $city
      state: $state
    ) {
      ...userFragment
    }
  }
`;

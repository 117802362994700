import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useUser from 'hooks/useUser';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { errorMonitor } from 'events';
import { useLocation, useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

// ----------------------------------------------------------------------
type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};
export default function ForgotPasswordForm() {
  const { changePassword } = useUser();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const code = params.get('code');

  const ForgotPasswordSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    passwordConfirm: Yup.string().required('Confirm Password is required')
  });

  const formik = useFormik<any>({
    initialValues: {
      password: '',
      passwordConfirm: ''
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const user = await changePassword({
          token: code,
          password: values.password
        });
        // if (user) {
        //   navigate(PATH_DASHBOARD.user.account);
        // }

        if (user.data.changeForgotPassword.success) {
          enqueueSnackbar(user.data.changeForgotPassword.message, {
            variant: 'success',
            action: (key) => (
              <IconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </IconButton>
            )
          });
          navigate(PATH_DASHBOARD.user.account);
        } else {
        }

        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        let message: string;
        if (error.status === 403 || error.message?.search('403')) {
          message = 'Wrong Password';
        } else {
          message = error.message;
        }
        enqueueSnackbar(message, {
          variant: 'error',
          action: (key) => (
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </IconButton>
          )
        });
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label="Confirm Password"
            {...getFieldProps('passwordConfirm')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
            helperText={touched.passwordConfirm && errors.passwordConfirm}
          />
        </Stack>

        <LoadingButton
          sx={{ marginTop: 2 }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={
            Boolean(errors.passwordConfirm) ||
            Boolean(errors.password) ||
            !values.passwordConfirm ||
            !values.passwordConfirm ||
            values.passwordConfirm !== values.password
          }
        >
          Change Password
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

import { Alert, Box, CircularProgress } from '@mui/material';
import { useState } from 'react';
import CCP_QUIZ from './CCP.json';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { LoadingButton } from '@mui/lab';
import { GetUserDocument, useChangeUserDataMutation } from 'generated/graphql';

interface QuizItem {
  question: string;
  answers: {
    label: string;
    score: number;
  }[];
}

interface ControlledRadioButtonsGroupProps {
  quizItem: QuizItem;
  onSelection: (answerObj: QuizItem['answers'][0], question: string) => void;
}

function ControlledRadioButtonsGroup({ quizItem, onSelection }: ControlledRadioButtonsGroupProps) {
  const [value, setValue] = useState('');

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <FormControl>
      <FormLabel
        sx={{
          fontWeight: 'bold',
          color: 'text.primary'
          //   color: 'common.white'
        }}
        id="demo-controlled-radio-buttons-group"
      >
        {quizItem.question}
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {quizItem?.answers?.map((answerObj) => (
          <FormControlLabel
            key={answerObj.label}
            onClick={() => onSelection(answerObj, quizItem.question)}
            value={answerObj.label}
            control={<Radio />}
            label={`${answerObj.label} - ${answerObj.score}`}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

function CCP({ onSaveResults }: { onSaveResults?: () => void }) {
  const [scores, setScores] = useState<{ [key: string]: any }>({}); //max of 60 for score and 12 questions
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);

  const [changeUserData, { data, loading }] = useChangeUserDataMutation({
    refetchQueries: [GetUserDocument],
    awaitRefetchQueries: true
  });

  const handleSelection = (answerObj: QuizItem['answers'][0], question: string) => {
    setScores((prev) => ({
      ...prev,
      [question]: answerObj.score
    }));
  };

  const scoreValues = Object.values(scores);
  const buttonDisabled = scoreValues.length === 12;

  const score = scoreValues.reduce((prevValue, currentValue) => prevValue + currentValue, 0);

  const handleSaveResults = () => {
    changeUserData({
      variables: {
        ccpScore: score
      },
      onCompleted: () => onSaveResults?.()
    });
  };

  const handleQuizProgress = () => {
    setCurrentQuestion((prev) => prev + 1);
  };

  if (loading) {
    return (
      <Box sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
        <h2>Saving Quiz Results...</h2>
        <CircularProgress />
      </Box>
    );
  }

  const currentQuizQuestion = CCP_QUIZ.filter((_, i) => i === currentQuestion).find(() => true);

  return (
    <Box sx={{ paddingBottom: 4 }}>
      <Alert severity="info" sx={{ mb: 2 }}>
        The purpose of this questionnaire is to measure your confidence on a wide range of auditory
        communication skills either with or without hearing aids. Answer each question on a scale of
        5 to 1, where 1 = Extremely; 2 = Very; 3 = Moderately; 4 = Slightly; and 5 = Not at all
      </Alert>
      <Box key={currentQuizQuestion?.question} sx={{ marginBottom: 2 }}>
        <ControlledRadioButtonsGroup
          onSelection={handleSelection}
          quizItem={currentQuizQuestion!}
        />
      </Box>
      {currentQuestion !== CCP_QUIZ.length - 1 && (
        <LoadingButton variant="contained" onClick={handleQuizProgress}>
          Next
        </LoadingButton>
      )}
      {currentQuestion === CCP_QUIZ.length - 1 && (
        <LoadingButton variant="contained" disabled={!buttonDisabled} onClick={handleSaveResults}>
          Complete
        </LoadingButton>
      )}
    </Box>
  );
}

export default CCP;

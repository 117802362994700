import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// material
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { useCallback } from 'react';
import { FormikHelpers } from 'formik';
import ProviderEditForm from 'components/_dashboard/user/providers/ProviderEditForm';
import { useMutation } from '@apollo/client';
import { EDIT_PROVIDER } from 'graphql/user/user.mutations';
import { pickBy } from 'lodash';

// ----------------------------------------------------------------------

interface LocationState {
  id: number;
  email: string;
  location: string;
}

export default function ProviderEdit() {
  const { themeStretch } = useSettings();
  const { pathname, state } = useLocation();
  const { orderId = '' } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [editProvider, { data, loading }] = useMutation(EDIT_PROVIDER);

  const isEdit = true;
  const providerDetails = state as LocationState;
  const isLoading = false;

  const handleUpdatingOrder = useCallback(
    async (values: any, formikHelpers: FormikHelpers<any>) => {
      const { id, email, city, state, password } = values;

      editProvider({
        variables: {
          editProviderId: parseInt(id),
          ...pickBy({
            editProviderEmail: email,
            editProviderPassword: password,
            city,
            state
          })
        }
      })
        .then(() => {
          enqueueSnackbar('Provider Successfully Updated', { variant: 'success' });
          navigate(PATH_DASHBOARD.user.myProviders);
        })
        .catch((e) => enqueueSnackbar(e.message, { variant: 'error' }));
    },
    [navigate, enqueueSnackbar, orderId]
  );

  const handleSubmit = useCallback(
    async (values: any, formikHelpers: FormikHelpers<any>) => {
      handleUpdatingOrder(values, formikHelpers);
    },
    [handleUpdatingOrder]
  );

  return (
    <Page title="Provider: Edit Provider">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create a new order' : 'Edit Provider'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'My Providers', href: PATH_DASHBOARD.user.myProviders },
            { name: !isEdit ? 'New order' : String(providerDetails.id) }
          ]}
        />

        <ProviderEditForm
          isEdit
          provider={providerDetails}
          onSubmit={handleSubmit}
          isLoading={loading}
        />
      </Container>
    </Page>
  );
}

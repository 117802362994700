import {
  Alert,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import produce from 'immer';
import COSI_QUIZ from './COSI.json';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { LoadingButton } from '@mui/lab';
import { GetUserDocument, useChangeUserDataMutation } from 'generated/graphql';

interface QuizItem {
  question: string;
  answers: {
    label: string;
    score: number;
  }[];
}

interface ControlledRadioButtonsGroupProps {
  quizItem: QuizItem;
  onSelection: (answerObj: QuizItem['answers'][0], question: string) => void;
  value: string;
}

export function ControlledRadioButtonsGroup({
  quizItem,
  onSelection,
  value
}: ControlledRadioButtonsGroupProps) {
  const [_value, setValue] = useState('');

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <FormControl>
      <FormLabel
        sx={{
          fontWeight: 'bold',
          color: 'text.primary'
          //   color: 'common.white'
        }}
        id="demo-controlled-radio-buttons-group"
      >
        {quizItem.question}
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {quizItem?.answers?.map((answerObj) => (
          <FormControlLabel
            key={answerObj.label}
            onClick={() => onSelection(answerObj, quizItem.question)}
            value={answerObj.label}
            control={<Radio />}
            label={`${answerObj.label} - ${answerObj.score}`}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

const categories = [
  'Conversation with 1 or 2 in quiet',
  'Television/Radio @ normal volume',
  'Hear front door bell or knock',
  'Feeling left out',
  'Conversation with 1 or 2 in noise',
  'Familiar speaker on phone',
  'Hear traffic',
  'Feeling upset or angry',
  'Conversation with group in quiet',
  'Unfamiliar speaker on phone',
  'Increased social contact',
  'Church or meeting',
  'Conversation with group in noise',
  'Hearing phone ring from another room',
  'Feel embarrassed or stupid',
  'Other'
];

interface FormState {
  id?: number;
  categorySelected: string;
  degreeOfChange: string;
  text: string;
}

function Categories({
  isCatComplete,
  values,
  onChange,
  catsToFilter
}: {
  isCatComplete: boolean;
  values: FormState;
  onChange: (state: FormState) => void;
  catsToFilter: string[];
}) {
  const handleSimpleForm = (e: any) => {
    if (!e) return;
    const name = e.target.name as 'categorySelected' | 'degreeOfChange' | 'text';
    const value = e.target.value as string;

    if (!name) {
      throw new Error('Name on input cannot be blank');
    }

    //@ts-ignore
    onChange({
      [name]: value
    });
  };

  return (
    <div>
      <div style={{ marginBottom: 8 }}>
        <TextField
          onChange={handleSimpleForm}
          name="categorySelected"
          select
          label="Category"
          placeholder="Category"
          margin="dense"
          fullWidth
          value={values?.categorySelected}
        >
          {categories.map((category) => (
            <MenuItem
              key={category}
              value={category}
              disabled={catsToFilter.indexOf(category) !== -1}
            >
              {category}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div style={{ marginBottom: 8 }}>
        <TextField
          name="text"
          onChange={handleSimpleForm}
          multiline
          maxRows={8}
          minRows={8}
          placeholder="Describe"
          fullWidth
          value={values.text}
        />
      </div>
      <div style={{ marginBottom: 8 }}>
        <ControlledRadioButtonsGroup
          value={values.degreeOfChange}
          onSelection={(answerObj: QuizItem['answers'][0], question: string) => {
            handleSimpleForm({
              target: {
                name: 'degreeOfChange',
                value: answerObj.label
              }
            });
          }}
          quizItem={{
            question: 'Degree of Change',
            answers: [
              {
                label: 'Worse',
                score: 0
              },
              {
                label: 'No Difference',
                score: 0
              },
              {
                label: 'Slightly Better',
                score: 0
              },
              {
                label: 'Better',
                score: 0
              },
              {
                label: 'Much Better',
                score: 0
              }
            ]
          }}
        />
      </div>
    </div>
  );
}

const initialCosiState = [
  {
    id: 0,
    categorySelected: '',
    text: '',
    degreeOfChange: ''
  },
  {
    id: 1,
    categorySelected: '',
    text: '',
    degreeOfChange: ''
  },
  {
    id: 2,
    categorySelected: '',
    text: '',
    degreeOfChange: ''
  },
  {
    id: 3,
    categorySelected: '',
    text: '',
    degreeOfChange: ''
  },
  {
    id: 4,
    categorySelected: '',
    text: '',
    degreeOfChange: ''
  }
];

function COSI() {
  const [cosiState, setCosiState] = useState(initialCosiState);
  const [currentCatIndex, setCurrentCatIndex] = useState(0);
  const currentCatObj = cosiState[currentCatIndex];
  const isCatComplete = Boolean(
    currentCatObj.categorySelected && currentCatObj.text && currentCatObj.degreeOfChange
  );
  const catsToFilter = cosiState.map((cosi) => cosi.categorySelected);
  const [scores, setScores] = useState<{ [key: string]: any }>({}); //max of 60 for score and 12 questions
  const [changeUserData, { data, loading }] = useChangeUserDataMutation({
    refetchQueries: [GetUserDocument],
    awaitRefetchQueries: true
  });

  const handleNextCategory = () => {
    setCurrentCatIndex((p) => p + 1);
  };

  const handleSelection = (answerObj: QuizItem['answers'][0], question: string) => {
    setScores((prev) => ({
      ...prev,
      [question]: answerObj.score
    }));
  };

  const scoreValues = Object.values(scores);
  const buttonDisabled = scoreValues.length === 12;

  const score = scoreValues.reduce((prevValue, currentValue) => prevValue + currentValue, 0);

  const handleSaveResults = () => {
    changeUserData({
      variables: {
        cosiScore: cosiState
      }
    });
  };

  if (loading) {
    return (
      <Box sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
        <h2>Saving Quiz Results...</h2>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ paddingBottom: 4 }}>
      <Alert severity="info" sx={{ mb: 1 }}>
        This tool is designed for you to self-report the benefit and satisfaction provided by your
        hearing aid(s). Identify five listening situations in which you’re struggling to communicate
        and would like help with hearing better. The five situations don’t need to be in any
        particular order.
      </Alert>
      <Typography sx={{ textAlign: 'center' }} variant="h5">
        {currentCatIndex + 1}/5 Current Step
      </Typography>
      <Categories
        catsToFilter={catsToFilter}
        values={cosiState[currentCatIndex]}
        isCatComplete={isCatComplete}
        onChange={(formState: FormState) =>
          setCosiState(
            produce((draftState) => {
              draftState[currentCatIndex] = {
                ...draftState[currentCatIndex],
                ...formState,
                id: currentCatIndex
              };
            })
          )
        }
      />
      {currentCatIndex < 4 && (
        <Button
          onClick={handleNextCategory}
          disabled={!isCatComplete}
          color="primary"
          variant="contained"
        >
          Next Category
        </Button>
      )}
      {currentCatIndex === 4 && (
        <LoadingButton
          loading={loading}
          onClick={handleSaveResults}
          disabled={!isCatComplete}
          color="primary"
          variant="contained"
        >
          Save Results
        </LoadingButton>
      )}
    </Box>
  );
}

export default COSI;

import { InMemoryCache, makeVar } from '@apollo/client';

export const addedExercisesVar = makeVar([]);

const cache = new InMemoryCache({
  typePolicies: {
    Mutation: {},
    Query: {}
  }
});

// cache.writeQuery({
//   query: GET_EXERCISES,
//   data: {
//     isLoggedIn: !!localStorage.getItem("token"),
//   },
// });

export default cache;

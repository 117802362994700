import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik, FormikHelpers } from 'formik';
// material
import { DateTimePicker, LoadingButton, DatePicker } from '@mui/lab';
import { Alert, Box, Card, Grid, Stack, TextField, MenuItem } from '@mui/material';
import { User } from 'generated/graphql';

// ----------------------------------------------------------------------

const NewOrderSchema = Yup.object().shape({
  location: Yup.string(),
  email: Yup.string().email(),
  password: Yup.string()
});

type PatientEditFormProps = {
  isEdit: boolean;
  onSubmit: (formValues: any, formickHelpers: FormikHelpers<any>) => void;
  patient?: User;
  isLoading: boolean;
};

export default function PatientEditForm({
  isEdit,
  onSubmit,
  patient,
  isLoading
}: PatientEditFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...patient,
      password: ''
    },
    validationSchema: NewOrderSchema,
    onSubmit
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
    setTouched,
    isValid
  } = formik;

  return (
    <FormikProvider value={formik}>
      {Object.keys(errors).length > 0 && (
        <Alert sx={{ marginBottom: 2 }} severity="error">
          Form is Incomplete
        </Alert>
      )}
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Name"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    fullWidth
                    label="Age"
                    {...getFieldProps('age')}
                    error={Boolean(touched.age && errors.age)}
                    helperText={touched.age && errors.age}
                  />
                  <TextField
                    select
                    label="Education Level"
                    placeholder="Education Level"
                    margin="dense"
                    fullWidth
                    {...getFieldProps('educationLevel')}
                    error={Boolean(touched.educationLevel && errors.educationLevel)}
                    helperText={touched.educationLevel && errors.educationLevel}
                  >
                    <MenuItem value={'GED'}>GED</MenuItem>
                    <MenuItem value={'Highschool Diploma'}>Highschool</MenuItem>
                    <MenuItem value={'Some College'}>Some College</MenuItem>
                    <MenuItem value={'Technical Degree'}>Technical Degree</MenuItem>
                    <MenuItem value={'Bachelors'}>Bachelors</MenuItem>
                    <MenuItem value={'Post Graduate Education'}>Post Graduate Education</MenuItem>
                  </TextField>
                  <TextField
                    select
                    label="Ethnicity"
                    placeholder="Ethnicity"
                    margin="dense"
                    fullWidth
                    {...getFieldProps('ethnicity')}
                    error={Boolean(touched.ethnicity && errors.ethnicity)}
                    helperText={touched.ethnicity && errors.ethnicity}
                  >
                    <MenuItem value={'White'}>White</MenuItem>
                    <MenuItem value={'Hispanic or Latino'}>Hispanic or Latino</MenuItem>
                    <MenuItem value={'Black or African American'}>
                      Black or African American
                    </MenuItem>
                    <MenuItem value={'Asian'}>Asian</MenuItem>
                    <MenuItem value={'Two or more Races'}>Two or more Races</MenuItem>
                    <MenuItem value={'Native Americans and Alaska Natives'}>
                      Native Americans and Alaska Natives
                    </MenuItem>
                    <MenuItem value={'Some other race'}>Some other race</MenuItem>
                    <MenuItem value={'Native Hawaiians and Other Pacific Islanders'}>
                      Native Hawaiians and Other Pacific Islanders
                    </MenuItem>
                  </TextField>
                  <TextField
                    select
                    label="Gender"
                    placeholder="Gender"
                    margin="dense"
                    fullWidth
                    {...getFieldProps('gender')}
                    error={Boolean(touched.gender && errors.gender)}
                    helperText={touched.gender && errors.gender}
                  >
                    <MenuItem value={'Male'}>Male</MenuItem>
                    <MenuItem value={'Female'}>Female</MenuItem>
                    <MenuItem value={'Other'}>Other</MenuItem>
                  </TextField>
                  <TextField
                    fullWidth
                    label="Education Level"
                    {...getFieldProps('age')}
                    error={Boolean(touched.educationLevel && errors.educationLevel)}
                    helperText={touched.educationLevel && errors.educationLevel}
                  />
                  {/* <TextField
                    fullWidth
                    label="State"
                    {...getFieldProps('state')}
                    error={Boolean(touched.state && errors.state)}
                    helperText={touched.state && errors.state}
                  />
                  <TextField
                    fullWidth
                    label="City"
                    {...getFieldProps('city')}
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                  /> */}
                  <TextField
                    fullWidth
                    label="Password"
                    {...getFieldProps('password')}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Email"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    disabled
                    fullWidth
                    label="HHI"
                    {...getFieldProps('hhieScore')}
                    error={Boolean(touched.hhieScore && errors.hhieScore)}
                    helperText={touched.hhieScore && errors.hhieScore}
                  />
                  <TextField
                    disabled
                    fullWidth
                    label="CCP"
                    {...getFieldProps('ccpScore')}
                    error={Boolean(touched.ccpScore && errors.ccpScore)}
                    helperText={touched.ccpScore && errors.ccpScore}
                  />
                </Stack>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={!touched || !isValid}
                    loading={isSubmitting || isLoading}
                  >
                    {!isEdit ? 'Create User' : 'Save Changes'}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

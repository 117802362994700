// routes
import { PATH_DASHBOARD } from './paths';
// components
import SvgIconStyle from '../components/SvgIconStyle';
import { UserRole } from 'generated/graphql';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      // Providers
      {
        title: 'My Providers',
        path: PATH_DASHBOARD.user.myProviders,
        icon: ICONS.analytics,
        role: UserRole.Admin
      },

      // Patients
      {
        title: 'Patients',
        path: PATH_DASHBOARD.user.patients,
        icon: <RecentActorsIcon />,
        role: UserRole.Provider
      },

      // Patient
      {
        title: 'results',
        path: PATH_DASHBOARD.user.patientResults,
        icon: ICONS.analytics,
        role: UserRole.Patient
      },
      {
        title: 'modules',
        path: PATH_DASHBOARD.user.patientLessons,
        icon: <ViewModuleIcon />,
        role: UserRole.Patient
      },
      {
        title: 'modules',
        path: PATH_DASHBOARD.user.patientLessons,
        icon: <ViewModuleIcon />,
        role: UserRole.Provider
      },
      {
        title: 'reports',
        path: PATH_DASHBOARD.user.reports,
        icon: ICONS.analytics,
        role: UserRole.Provider
      },
      {
        title: 'modules',
        path: PATH_DASHBOARD.user.patientLessons,
        icon: <ViewModuleIcon />,
        role: UserRole.Admin
      },
      {
        title: 'account',
        path: PATH_DASHBOARD.user.account,
        icon: ICONS.user,
        role: UserRole.Patient
      }
    ]
  }
];

export default sidebarConfig;

// hooks
import useUser from '../hooks/useUser';
//
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { account } = useUser();

  return (
    <MAvatar src={account?.photo} alt={account?.businessName} color={'primary'} {...other}>
      {createAvatar(account?.businessName ?? '').name}
    </MAvatar>
  );
}

// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// hooks
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import useUser from 'hooks/useUser';
import { ErrorBoundary } from 'react-error-boundary';
import { Alert, Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';

// ----------------------------------------------------------------------

function ErrorFallback({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: any }) {
  return (
    <Alert severity="error">
      <p>Something went wrong, please reach out to the site admin:</p>
      <pre>{error.message}</pre>
      <Button onClick={resetErrorBoundary} variant="contained" color="primary">
        Go to Dashboard
      </Button>
    </Alert>
  );
}

export default function App() {
  const navigate = useNavigate();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        navigate(PATH_DASHBOARD.root);
      }}
    >
      <ThemeConfig>
        <ThemePrimaryColor>
          <ThemeLocalization>
            <RtlLayout>
              {/* material ui third party library that handles toasts */}
              <NotistackProvider>
                <GlobalStyles />
                <ProgressBarStyle />
                <Settings />
                <ScrollToTop />
                <GoogleAnalytics />
                <Router />
              </NotistackProvider>
            </RtlLayout>
          </ThemeLocalization>
        </ThemePrimaryColor>
      </ThemeConfig>
    </ErrorBoundary>
  );
}

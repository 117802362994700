import { Icon } from '@iconify/react';
import { paramCase } from 'change-case';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { PATH_DASHBOARD } from 'routes/paths';

import micFill from '@iconify/icons-eva/mic-fill';
import alert from '@iconify/icons-eva/alert-triangle-fill';
import chartFill from '@iconify/icons-eva/bar-chart-fill';

// routes

// ----------------------------------------------------------------------

type ProviderMoreMenuProps = {
  onDelete: VoidFunction;
  onEdit: VoidFunction;
  onCosiSelect?: () => void;
  onManageLessonAccess?: () => void;
  onFamilyRecording?: () => void;
};

export default function ProviderMoreMenu({
  onDelete,
  onEdit,
  onCosiSelect,
  onManageLessonAccess,
  onFamilyRecording
}: ProviderMoreMenuProps) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handlePatientDelete = () => {
    setIsOpen(false);
    onDelete();
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 250, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          //   component={RouterLink}
          //   to={`${PATH_DASHBOARD.root}`}
          sx={{ color: 'text.secondary' }}
          onClick={onEdit}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem onClick={handlePatientDelete} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {onCosiSelect && (
          <MenuItem
            //   component={RouterLink}
            //   to={`${PATH_DASHBOARD.root}`}
            sx={{ color: 'text.secondary' }}
            onClick={onCosiSelect}
          >
            <ListItemIcon>
              <Icon icon={chartFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="COSI Results" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {onManageLessonAccess && (
          <MenuItem
            //   component={RouterLink}
            //   to={`${PATH_DASHBOARD.root}`}
            sx={{ color: 'text.secondary' }}
            onClick={onManageLessonAccess}
          >
            <ListItemIcon>
              <Icon icon={alert} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Lesson Access" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {onFamilyRecording && (
          <MenuItem
            //   component={RouterLink}
            //   to={`${PATH_DASHBOARD.root}`}
            sx={{ color: 'text.secondary' }}
            onClick={onFamilyRecording}
          >
            <ListItemIcon>
              <Icon icon={micFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Family Recording"
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

import { Alert, Box, CircularProgress, TextField } from '@mui/material';
import { useState } from 'react';
import HHIE_QUIZ from './HHIE.json';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { LoadingButton } from '@mui/lab';
import { GetUserDocument, useChangeUserDataMutation } from 'generated/graphql';

interface QuizItem {
  question: string;
  questionType: string;
  answers: {
    label: string;
    score: number;
  }[];
}

interface ControlledRadioButtonsGroupProps {
  quizItem: QuizItem;
  onEmotion: (answerObj: QuizItem['answers'][0], question: string) => void;
  onSituation: (answerObj: QuizItem['answers'][0], question: string) => void;
}

function ControlledRadioButtonsGroup({
  quizItem,
  onEmotion,
  onSituation
}: ControlledRadioButtonsGroupProps) {
  const [value, setValue] = useState('');

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const onSubmit = (answerObj: QuizItem['answers'][0]) => {
    switch (quizItem.questionType) {
      case 's':
        onSituation(answerObj, quizItem.question);
        break;
      default:
        onEmotion(answerObj, quizItem.question);
        break;
    }
  };

  return (
    <FormControl>
      <FormLabel
        sx={{
          fontWeight: 'bold',
          color: 'text.primary'
          //   color: 'common.white'
        }}
        id="demo-controlled-radio-buttons-group"
      >
        {quizItem.question}
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {quizItem?.answers?.map((answerObj) => (
          <FormControlLabel
            onClick={() => onSubmit(answerObj)}
            key={answerObj.label}
            value={answerObj.label}
            control={<Radio />}
            label={`${answerObj.label} - ${answerObj.score}`}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

function HHIE({ onSaveResults }: { onSaveResults?: () => void }) {
  const [emotionScores, setEmotionScores] = useState<{ [key: string]: any }>({}); //13
  const [situationScores, setSituationScores] = useState<{ [key: string]: any }>({}); //12
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);

  const [changeUserData, { data, loading }] = useChangeUserDataMutation({
    refetchQueries: [GetUserDocument],
    awaitRefetchQueries: true
  });

  const handleEmotion = (answerObj: QuizItem['answers'][0], question: string) => {
    setEmotionScores((prev) => ({
      ...prev,
      [question]: answerObj.score
    }));
  };
  const handleSituation = (answerObj: QuizItem['answers'][0], question: string) => {
    setSituationScores((prev) => ({
      ...prev,
      [question]: answerObj.score
    }));
  };

  const emotionScoreValues = Object.values(emotionScores);
  const situationScoreValues = Object.values(situationScores);
  const buttonDisabled = emotionScoreValues.length === 13 && situationScoreValues.length === 12;

  const score = [...emotionScoreValues, ...situationScoreValues].reduce(
    (prevValue, currentValue) => prevValue + currentValue,
    0
  );

  const handleSaveResults = () => {
    changeUserData({
      variables: {
        hhieScore: score
      },
      onCompleted: () => onSaveResults?.()
    });
  };

  const handleQuizProgress = () => {
    setCurrentQuestion((prev) => prev + 1);
  };

  if (loading) {
    return (
      <Box sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
        <h2>Saving Quiz Results...</h2>
        <CircularProgress />
      </Box>
    );
  }

  const currentQuizQuestion = HHIE_QUIZ.filter((_, i) => i === currentQuestion).find(() => true);

  return (
    <Box sx={{ paddingBottom: 4 }}>
      <Alert severity="info" sx={{ mb: 2 }}>
        The purpose of this scale is to identify the problems your hearing loss may be causing you.
        Check ‘Yes’, ‘Sometimes’, or ‘No’ for each question. Do not skip any questions. If you use a
        hearing aid, please answer the way you hear without a hearing aid.
      </Alert>
      <Box sx={{ marginBottom: 2 }}>
        <ControlledRadioButtonsGroup
          key={currentQuizQuestion?.question}
          onEmotion={handleEmotion}
          onSituation={handleSituation}
          quizItem={currentQuizQuestion!}
        />
      </Box>
      {currentQuestion !== HHIE_QUIZ.length - 1 && (
        <LoadingButton variant="contained" onClick={handleQuizProgress}>
          Next
        </LoadingButton>
      )}
      {currentQuestion === HHIE_QUIZ.length - 1 && (
        <LoadingButton variant="contained" disabled={!buttonDisabled} onClick={handleSaveResults}>
          Complete
        </LoadingButton>
      )}
    </Box>
  );
}

export default HHIE;

import { gql } from '@apollo/client';

export const userFragment = gql`
  fragment userFragment on User {
    id
    userRole
    onboardingComplete
    age
    educationLevel
    ethnicity
    gender
    email
    name
    lastLogin
    city
    state
  }
`;

import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import MyProviders from 'pages/dashboard/MyProviders';
import ProviderEdit from 'pages/dashboard/ProviderEdit';
import { useGetUserQuery, UserRole } from 'generated/graphql';
import PatientEdit from 'pages/dashboard/PatientEdit';
import PatientResults from 'pages/dashboard/PatientResults';

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

function checkUserRole(userCurrentRole: UserRole, allowedRoles: UserRole[]) {
  return (obj: any) => {
    if (allowedRoles.includes(userCurrentRole) || allowedRoles.includes(UserRole.All)) {
      return obj;
    }

    return [];
  };
}

export default function Router() {
  const { data: user, loading } = useGetUserQuery();

  const routes = useRoutes(
    loading
      ? []
      : [
          {
            path: 'auth',
            children: [
              {
                path: 'login',
                element: (
                  <GuestGuard>
                    <Login />
                  </GuestGuard>
                )
              },
              {
                path: 'change-password',
                element: (
                  <GuestGuard>
                    <ForgotPassword />
                  </GuestGuard>
                )
              },
              {
                path: 'forgot-password',
                element: (
                  <GuestGuard>
                    <ForgotPassword request />
                  </GuestGuard>
                )
              }
            ]
          },

          // Dashboard Routes
          {
            path: 'dashboard',
            element: (
              <AuthGuard>
                <DashboardLayout />
              </AuthGuard>
            ),
            children: [
              ...checkUserRole(user?.getUser?.userRole as UserRole, [UserRole.Admin])([
                {
                  element: <Navigate to="/dashboard/my-providers" replace />
                },
                {
                  path: 'my-providers',
                  element: <MyProviders />
                  // children: [
                  //   {
                  //     element: <ProviderEdit />,
                  //     path: 'edit/:id'
                  //   }
                  // ]
                },
                {
                  path: 'providers',
                  children: [
                    // { element: <Navigate to="/dashboard/edit" replace /> },
                    {
                      element: <ProviderEdit />,
                      path: 'edit/:id'
                    }
                  ]
                }
              ]),
              ...checkUserRole(user?.getUser?.userRole as UserRole, [UserRole.Provider])([
                {
                  element: <Navigate to="/dashboard/patients" replace />
                },
                {
                  path: 'patients',
                  element: <Patients />
                },
                {
                  path: 'patients/edit/:id',
                  element: <PatientEdit />
                },
                {
                  path: 'reports',
                  element: <Reports />
                },
                {
                  path: 'family-recording',
                  element: <FamilyRecording />
                },
                {
                  path: 'lesson-access',
                  element: <LessonAccess />
                }
              ]),
              ...checkUserRole(user?.getUser?.userRole as UserRole, [UserRole.Patient])([
                {
                  element: <Navigate to="/dashboard/results" replace />
                },
                {
                  path: 'results',
                  element: <PatientResults />
                }
              ]),
              ...checkUserRole(user?.getUser?.userRole as UserRole, [
                UserRole.Patient,
                UserRole.Provider,
                UserRole.Admin
              ])([
                {
                  path: 'lessons',
                  element: <Lessons />
                },
                {
                  path: 'lesson',
                  element: (
                    <div style={{ padding: '0 12px' }}>
                      <Lesson />
                    </div>
                  )
                }
              ]),
              { path: 'account', element: <UserAccount /> },

              // {
              //   path: 'order',
              //   children: [
              //     { element: <Navigate to="/dashboard/order/profile" replace /> },
              //     { path: 'list', element: <OrderList /> },
              //     { path: 'new', element: <OrderCreate /> },
              //     { path: ':orderId/edit', element: <OrderCreate /> }
              //   ]
              // },
              {
                path: 'user',
                children: [
                  { element: <Navigate to="/dashboard/user/profile" replace /> },
                  { path: 'account', element: <UserAccount /> }
                ]
              }
            ]
          },

          // Main Routes
          {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
              { path: '500', element: <Page500 /> },
              { path: '404', element: <NotFound /> },
              { path: '*', element: <Navigate to="/auth/login" replace /> }
            ]
          },
          { path: '*', element: <Navigate to="/404" replace /> },
          {
            path: '/',
            element: <Navigate to="/dashboard" />
          }
        ]
  );

  if (loading) {
    return <LoadingScreen />;
  }

  return routes;
}

const Lesson = Loadable(lazy(() => import('../pages/dashboard/lesson')));
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const ForgotPassword = Loadable(lazy(() => import('../pages/authentication/ForgotPassword')));
const Patients = Loadable(lazy(() => import('../pages/dashboard/Patients')));
const Lessons = Loadable(lazy(() => import('../pages/dashboard/Lessons')));
const Reports = Loadable(lazy(() => import('../pages/dashboard/Reports')));
const FamilyRecording = Loadable(lazy(() => import('../pages/dashboard/FamilyRecording')));
const LessonAccess = Loadable(lazy(() => import('../pages/dashboard/LessonAccess')));

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
